@import "../../assets/variableStyles.scss";

.offer-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  // padding: 0 0px;
  h3 {
    font-weight: 700;
    color: #bd0c21 !important;
    font-size: 30px;
  }
  .offer-card {
    border: 3px dashed rgba(122, 116, 116, 0.125) !important;

    .offer-card-title {
      font-weight: 700;
      font-size: 1rem;
      color: #444 !important;
    }
    .offer-card-img {
      width: 60px;
      height: 60px;
      border: 1px solid #ddd3d3;
      border-radius: 50%;
      object-fit: cover;
    }
    .offer-card-body {
      padding: 2.225rem;
      small {
        color: rgba(133, 130, 130, 1);
      }
    }
  }
  /* the slides */
  .slick-slide {
    padding: 0 10px;
  }
  .slick-arrow {
    top: -23px;
  }
  /* the parent */
  .slick-list {
    padding: 0 -10px;
    height: fit-content !important;
  }
  .slick-prev {
    left: 89%;
  }
  .slick-next {
    right: 30px !important;
  }
  .slick-next::before,
  .slick-prev::before {
    color: #000;
    font-weight: 600;

    line-height: 8px;
    opacity: 1;
  }
  .slick-prev,
  .slick-next {
    z-index: 10;
    position: absolute;
    display: block;
    height: 40px !important;
    width: 40px !important;
    line-height: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px #00000057;
    border-radius: 50%;
  }

  .visImage img {
    height: 100%;
    width: 100%;
  }

  .visImage {
    height: 150px;
    overflow: hidden;
    width: 100%;
  }
}

.fleerMasterCarausal {
  max-width: 1100px;
  margin: 0 auto;
  h3 {
    font-weight: 700;
    color: #444 !important;
    font-size: 25px;
  }
  .fleet-card {
    min-height: 344px;
    .aircraft-type {
      color: #bd0c21;
    }

    border-bottom: 3px solid #bd0c21;

    background-color: #fff;
    border-radius: 2px;
    transition: 0.3s ease-out;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    .ant-card-body {
      padding: 0px !important;
    }
    .fleet-content {
      padding: 15px;
      .fleet-name {
        font-size: 16px;
      }
    }
  }
  /* the slides */
  .slick-slide {
    padding: 0 10px;
  }
  .slick-arrow {
    top: -23px;
  }
  /* the parent */
  .slick-list {
    padding: 0 -10px;
  }
  .slick-prev {
    left: 89%;
  }
  .slick-next {
    right: 30px !important;
  }
  .slick-next::before,
  .slick-prev::before {
    color: #000;
    font-weight: 600;

    line-height: 8px;
    opacity: 1;
  }
  .slick-prev,
  .slick-next {
    z-index: 10;
    position: absolute;
    display: block;
    height: 40px !important;
    width: 40px !important;
    line-height: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px #00000057;
    border-radius: 50%;
  }

  .fleetImage img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .fleetImage {
    height: 150px;
    overflow: hidden;
    width: 100%;
  }

  .fleet-btn-wrapper {
    .fleet-btn {
      background-color: #bd0c21;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      padding: 0px 20px;
      border-color: #bd0c21;
      letter-spacing: 0.5px;
      border: 2px solid;
    }
    :focus,
    :hover {
      background-color: #bd0c21 !important;
      border-color: #000;
    }
  }
}
