.remtype {
  font-size: 14px;
}
.store-visit-tr {
  padding-top: 30px;
}
.store-modal-tr {
  width: 900px !important;
}
.action-image {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
  img {
    max-width: 35px;
    max-height: 35px;
    margin-right: 15px;
  }
}

.store-modal-tr .ant-upload {
  height: 58px !important;
}
.store-img-1 .ant-upload {
  height: 58px !important;
}
.store-vist-v3 {
  .ant-form-item {
    margin-bottom: 4px;
  }
}
