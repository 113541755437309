.busmtb {
  margin: 16px 0;
}

.car-tran-sucss {
  .paymet-booking-tic {
    h4 {
      font-weight: 600;
      font-size: 18px;
      color: #bd0c21;
    }

    p {
      font-size: 14px;
    }
  }
}