.card-add-heading {
    h5 {
        font-size: 18px;
        color: #bd0c21;
    }
}
.manage-department-input-container {
    padding: 0 15px;
    .avatar-uploader > .ant-upload {
        height: 110px;
        width: 110px;
    }
    .login-btn-container {
        text-align: end;
    }
}