@import "../../assets/variableStyles.scss";

.About-us-page {
    margin: 0 auto;
    width: 80%;

    .hdr {
        display: flex;
        justify-content: center;
        // padding-top: 60px;
        // background: #bd0c21;

        h2 {
            color: #bd0c21;
            border-bottom: 2px solid #bd0c21;
            font-weight: 600;
            font-size: 2.5em;
        }
    }
    @include mobile{
        width: 100%;
    }
}

.container-box {
    max-width: 100%;
    padding: 1em;
    display: flex;

    align-items: center;
    justify-content: space-between;
    @include mobile{
        padding: 0;
        display: block;
    }
}

.textContainer {
    flex: "0 0 50%";
    padding: "20px";
}

.image {
    flex: "0 0 50%";
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.heading-txt {
    font-family: playfair display;
    font-size: 2.2em;
    font-weight: 600;
    color: #bd0c20;
}

.paragraph {
    font-size: 1.2em;
    line-height: 1.5;
    color: #333;
}

.wrapper-part {
    width: 100%;
    margin: 0 auto;
    height: 400px;
    background: url(https://www.anjmal.com/wp-content/uploads/2019/10/dubai.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}

.content {
    max-width: 1024px;
    width: 100%;
    padding: 0 4%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading1 {
    font-size: 4em;
    color: white;
    font-family: playfair display;
}

.container1 {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.heading2 {
    font-family: playfair display;
    font-size: 2.5em;
    font-weight: 600;
    color: #bd0c20;
}

.cont {
    display: flex;
    // align-items: center;
    @include mobile{
        display: contents;
    }
}

.imagestyle {
    width: 35%;
    height: 35%;
    padding-right: 1em;
    padding-top: 8px;
    // box-shadow: 2px 3px 2px rgb(198, 198, 198);
    @include mobile{
        width: 100%;
    }
}

.para {
    font-size: '1em';
    line-height: '1.5';
}

.container-contact {
    margin-top: 10px;
    text-align: center;
}

.playfair {
    font-family: playfair display;
    font-weight: 600;
    font-size: 3em;
    color: #bd0c21;
}

.row {
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    @include mobile{
        display: contents;
    }
}

.sub-cont {
    flex: 1;
    margin: 0;
    @include mobile{
        margin: 2%;
    }
}

.img-1 {
    flex: 1;
    box-shadow: 1px 6px 20px 9px #ccc;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btn-primary {
    background: #bd0d21 !important;
    opacity: 1 !important;
    padding: 10px 18px 10px 18px !important;
    box-shadow: none !important;
    border-width: 0px !important;
    border-style: solid !important;
    border-color: #000000 !important;
    border-color: RGBA(0, 0, 0, 1) !important;
    border-radius: 0px !important;
}

a {
    color: #bd0c21;
}

.footer-container {
    padding: 20px 0;
}

.feature {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    width: 30px;
    margin-right: 10px;
}
.hdr-img{
    img{
       
        @include mobile{
            width: 100%;
            padding-top: 70px;
        }
    }
}