@import "../../assets/variableStyles.scss";

/* Map */
.map-container {
  position: relative;
  .map-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 65px;
    z-index: 1;
    width: 100%;
    background: #fff;
    padding: 15px 25px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    p {
      margin-bottom: 0;
    }
    .hotel-count {
      font-size: 24px;
      font-family: "Open Sans" ;
      span {
        font-size: 16px;
      }
    }
    .map-close {
      font-size: 14px;
      color: #bd0c21;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .child-modal-container {
    position: absolute;
    right: 30px;
    top: 90px;
  }
}

/* Marker */
.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #002172;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    z-index: 1;
  }
}

/* Hotel details Modal */
.hotel-det-modal {
  position: relative;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(28, 43, 57, 0.17);
  .det-modal-close {
    position: absolute;
    right: -10px;
    top: -10px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    font-size: 24px;
    background: #1c2b39;
    border-radius: 50%;
    box-shadow: 0 2px 2px 0 rgba(28, 43, 57, 0.17);
    span {
      color: #fff;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-content: center;
      font-weight: 700;
    }
    @include mobile {
      display: none;
    }
  }
  .hotel-details-modal-wrapper {
    .hotel-modal-header {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 10px 15px;
      .hotel-modal-image {
        max-width: 100px;
        height: 100px;
        width: 100px;
        img {
          width: 100%;
          height: 100%;
        }
        @include mobile {
          height: 75px;
          width: 75px;
        }
      }
      .hotel-modal-info {
        padding-left: 10px;
        width: 170px;
        h6 {
          font-size: 14px;
          font-weight: 700;
          color: #bd0c21;
          cursor: pointer;
        }
        .modal-star-rating {
          li {
            margin-right: 0 !important;
          }
        }
        .hotel-modal-rating {
          display: flex;
          margin-top: 13px;
          .rating-value {
            background: #eaf2fc;
            text-align: center;
            font-size: 10px;
            padding: 3px 7px;
            letter-spacing: 1px;
            line-height: 30px;
            .rating-count {
              font-size: 12px;
              font-weight: 700;
              color: #1c2b39;
              line-height: 1;
            }
          }
          .hotel-modal-reviews {
            margin-left: 7px;
            p {
              font-size: 12px;
              margin-bottom: 0;
            }
          }
          @include mobile {
            margin-top: 10px;
          }
        }
        @include mobile {
          width: 100%;
        }
      }
    }
    .hotel-modal-footer {
      text-align: end;
      background-color: #f6f7f8;
      padding: 10px;
      .price-and-count {
        display: flex;
        justify-content: flex-end;
        .price-and-count-wrapper {
          p {
            margin-bottom: 0;
            font-size: 21px;
            font-weight: 700;
            color: #1c2b39;
          }
          small {
            font-size: 85%;
            line-height: 1;
            margin-bottom: 7px;
            display: inherit;
          }
        }
        button {
          margin-left: 7px;
          padding: 1px 15px;
          border-radius: 3px;
          background-color: #bd0c21;
          i {
            font-size: 24px;
          }
        }
      }
      .web-show-room-btn {
        button {
          width: 100%;
          font-weight: 700;
          background: #bd0c21;
          border-radius: 3px;
        }
      }
    }
  }
}
