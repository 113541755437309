.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}

.travelDateContent {
  background-color: #e6f7ff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  p {
    margin-bottom: 0px;
  }
  span {
    font-size: 14px;
  }
}

.travel-calendar {
  .ant-form-item {
    margin: 0;
  }
  .ant-picker-calendar-header {
    padding: 0;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date-content {
    height: 36px;
  }
}
