@import '../../../assets/variableStyles.scss';

.busFareDetail_wrapper {
  .price_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .total_price {
    font-size: 18px;
    font-weight: 600;

    p {
      margin-bottom: 0;
    }
  }

  margin-bottom: 25px;
}

.buspromo_wrapper {
  .name {
    font-size: 18px;
    font-weight: 600;
  }

  .applied {
    color: #d5436a;
    font-weight: 600;
  }

  .ant-form {
    button {
      background-color: #bd0c21;
      color: #fff;
      border: none;
      font-size: 16px;
      // border-radius: 40px;

    }
  }
}

.visa-det-cont-alig {
  padding-left: 2%;

  p {
    strong {
      color: #bd0c21 !important;
      font-size: 16px;
    }

    span {
      color: black !important;
    }
  }
}
.right_wrapper{
  border: 1px solid #35459c;
  padding: 4px;
  border-radius: 4px;
  
  .title1{
    background: linear-gradient(to right, #bd0c21, #35459c);
    color: #fff;
    border-radius: 4px;
    font-size: 20px;
    margin-top: -18px;
    padding: 1px 20px 1px 4px;
    max-width:fit-content ;
    // border-bottom-right-radius: 8px;
    
  }

}