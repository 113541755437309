.thankyou-panel1 {
  .ant-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: rgb(19, 41, 104 / 20%) 0px 2px 5px 0;
  }
  h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  h4 {
    font-size: 16px;
    text-transform: uppercase;
    line-height: 19px;
    font-weight: 900;
    color: rgb(116, 116, 116);
  }
  .scroll-color {
    color: rgb(116, 116, 116);
  }
}
.ourteam-bg-panel {
  max-width: 1100px !important;
  margin: auto;
  padding: 0 15px;
  background: transparent !important;
  margin-bottom: 15px;
  margin-top: 15px;
}
.card-request {
  margin-top: 25px;
}
.top-bar-one {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
}
.top-bar-one-1 {
  text-align: center;
  padding: 50px 0px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
}
.ct-flight-card {
  .one-ct-1 {
    color: #000;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 4px;
    font-weight: 900;
  }
  .one-ct-2 {
    color: #000;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
    font-weight: 900;
  }
  .one-ct-3 {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 0px;
  }
  .one-ct-tr {
    margin-bottom: 0px;
  }
  .ct-details-btn {
    padding: 0px 17px;
    font-size: 15px;
    border-radius: 15px;
  }
}
.inner-ct-card {
  margin-top: 20px;
}
.approad-ct {
  display: inline-flex;
  padding: 2px 8px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  background: linear-gradient(90deg, rgb(67, 225, 168), rgb(40, 190, 178));
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: 9%;
  transform: translateY(-50%);
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}
.approad-1 {
  display: inline-flex;
  padding: 2px 8px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  background: linear-gradient(90deg, rgb(67, 225, 168), rgb(40, 190, 178));
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: 9%;
  transform: translateY(-50%);
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}
.out-ct-policy {
  font-size: 10px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  border-radius: 8px;
  flex-shrink: 0;
  text-transform: uppercase;
  background: linear-gradient(90deg, rgb(245, 81, 95), rgb(159, 4, 4));
  height: auto;
  padding: 2px 8px;
}
.star-rating-icon {
  display: inline-flex;
}
.star-rating-icon li {
  display: inline;
  padding: 0px 5px;
}
