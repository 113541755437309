.contact_wrapper {
  // background-color: #f5fbf7;
  background-color: #ff603b;
  background-image: linear-gradient(to right, #bd0c21, #35459c);
  padding: 100px 0 0 0;
  width: 100%;
  .inner_wrapper {
    padding: 8px;

    .contact_header {
      text-align: center;
      margin-bottom: 16px;
      h2 {
        font-size: 24px;
        color: #ffff;
        font-weight: 600;
      }
    }
    .dateStyle {
      width: 100%;
    }
    .isdCode {
      width: 30%;
    }
    .mobile {
      width: 70%;
    }
    .center {
      text-align: center;
    }
    Button {
      margin-top: 10px;
    }
  }
}
.from-contact-24 .ant-form-item {
  margin-bottom: 6px;
}
