.payment-btn {
  height: auto;
  // padding: 10px 20px;
  font-size: 18px;
  // font-weight: 600;
  // background-color: #ea1b37 !important;
  // color: #fff;
  // border: none;
  border-radius: 8px;
  background-color: #0123ad;
  background-image: linear-gradient(to right, #320063, #0f76bb);
  color: #fff;
  border: none;
  // font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
  // height: 100%;
}

.payment-btn:hover {
  height: auto;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  // background-color: #ea1b37 !important;
  background-color: #0123ad;
  // background-image: linear-gradient(to right, #320063, #0f76bb);
  color: #fff;
  border: none;
  border-radius: 8px;
}
