// @import "../../assets/variableStyles.scss";
// .pay_success_area {
//   .pay_success {
//     text-align: center;
//   }
// }



@import "../../assets/variableStyles.scss";
.pay_success_area {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 20px 0;
  .inner_area {
    width: 100%;
    max-width: 1200px;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
  }
}
.status-payment-data{
  text-align: center;
}

.success_area{
  padding-top: 40px !important;
}