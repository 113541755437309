.gst-number-feld {
  margin-bottom: 20px;
  p {
    margin-bottom: 0px;
    font-weight: 600;
  }
}
.gst-number-feld-1 {
  background: beige;
  padding: 15px 15px 15px;
  p {
    margin-bottom: 0px;
  }
}
.gst-group {
  background: beige;
  padding: 15px 15px 15px;
  border-radius: 5px;
}
.gst-data-row {
  font-weight: 700;
}
