.walletBody{
    margin-left: 30px;
}
.wallect-user{
    text-align: center;
}
.wallct-markup-sec .card-add-heading{
    display: block !important;
  
    justify-content: space-between;
    align-items: center;
}
.failedmesg{
    color:#bd0c21;
    font-size:16px;
    font-weight: 600;
}
.successmesg{
    color:#1e4167;
    font-size:16px;
    font-weight: 600;
}
.wallet-img{
    justify-content: center;
}
.wallect-input{
    padding-top: 16px;
}
.wallect-para{
    padding-left: 29%;
    margin-bottom: 0px;
}
.add-money-wallet{
    width: 100%;
}
