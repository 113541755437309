@import "../../../assets/variableStyles.scss";

.car-box-results {
  width: fit-content;
  display: -webkit-box;
  display: flex;
  padding: 0 4px;
  -webkit-box-align: center;
  align-items: center;
  height: 74px;
  border-radius: 4px;
  border: 1px solid #eee;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  cursor: pointer;
  position: relative;
}

.car-price-img-1 {
  width: 60px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: cover;
}

.car-one-text {
  display: flex;

  flex-direction: column;
  margin-left: 6px;
}

.car-one-text .car-compact {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
}

.car-one-text .car-compact-price {
  font-size: 12px;
  color: #999;
}

.cars-margins {
  margin-top: 25px;
}

.filters-cars-fvt {
  padding: 0 20px;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 4px;
}

.filters-cars-fvt .group-cars {
  /*   padding: 20px 0; */
  /*  border-bottom: 1px solid #eee; */
  margin-bottom: -1px;
}

.car-seats {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
}

.results-cars-trt {
  margin-top: 25px;
}

.seats-more {
  margin-bottom: 0px !important;
}

.cars-acvtt {
  border-radius: 4px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  margin-left: 10px;
}

.results-tp p {
  line-height: 14px;
  font-size: 12px;
  color: #999;
  padding: 5px 10px;
  margin-bottom: 0px !important;
}

.results-tp span {
  line-height: 14px;
  font-size: 12px;
  color: #999;
  padding: 5px 10px;
  margin-bottom: 0px !important;
}

.price-lh {
  text-align: right;
}

.nisha-cars {
  margin-bottom: 2px;
  line-height: 24px;
  word-break: break-word;
  font-size: 18px;
  font-weight: 700;
  color: #bd0c21 !important;
  vertical-align: middle;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

}

.sub-header-info {
  b {
    color: #bd0c21;
  }
}

.seat-label-cars li {
  float: left;
  margin: 0 20px 0 0px;
  font-size: 14px;
}

.visa-btn-price {
  justify-content: flex-end;
  border-left: 1px solid #f5f5f5;

  padding-top: 12px;
  padding-bottom: 6px;
}

.packages-num {
  align-items: flex-end;
  color: #999;
  flex-direction: column;
  display: flex;
  margin-bottom: 12px;
}

.cost-of-values {
  align-items: flex-end;

  flex-direction: column;
  float: right;
  margin-top: 5px;
}

.filters-cars-fvt .ant-collapse {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}

.filters-cars-fvt .anticon {
  width: 100%;
  text-align: end !important;
  left: 0 !important;
  right: 0px;
}

.filters-cars-fvt .ant-collapse-header {
  padding-left: 0px !important;
  padding: 0px 0px !important;
  font-weight: bold;
  color: #000;
}

.filters-cars-fvt .ant-collapse-content-box {
  padding: 16px 0px !important;
}

.filters-cars-fvt .ant-collapse-item {
  border-bottom: 0px solid #d9d9d9 !important;
}

@media screen and (max-width: 768px) {
  .car-box-results {
    width: 100%;
    margin-bottom: 8px;
  }

  .visa-btn-price {
    justify-content: flex-end;
    border-left: 0px solid #f5f5f5;
    height: 43px;
    padding-top: 0px !important;
  }

  .seat-label-cars li {
    float: left;
    margin: 0px 9px 0 0px;
    font-size: 13px;
  }

  .seat-label-cars {
    padding-top: 0px;
  }

  .cost-of-values {
    font-size: 14px;
  }

  .seats-chair-certi img {
    display: none !important;
  }

  .visa-ratings-act {
    .visa-image {
      width: 100% !important;
    }

    .visa-info-content {
      padding: 1rem !important;
    }

    .cars-btn-price {
      height: auto !important;

      .price-drops {
        padding: 16px;
        background-color: #f6f7f8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;

        h5 {
          margin: 0 !important;
        }
      }
    }
  }
}

/* car--rental--details--css */

.location-pickup {
  background: #fafafa;
  border-radius: 4px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.pick-rule {
  margin: 16px;
}

.pick-drop-tittle {
  margin: 4px 0;
  position: relative;
  padding: 0 0 0 20px;
}

.pick-drop-tittle::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #46b925;
  border-radius: 50%;
}

.pick-drop-rule-mark {
  margin: 4px 0;
  color: #0a9967;
  font-size: 12px;
  line-height: 14px;
  padding: 0 0 0 20px;
}

.pick-drop-date {
  margin: 8px 0;

  padding: 0 0 0 20px;
}

.pick-rule-1 {
  margin: 16px;
}

.pick-drop-tittle-1 {
  margin: 4px 0;
  position: relative;
  padding: 0 0 0 20px;
}

.pick-drop-tittle-1::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #bd0c21;
  border-radius: 50%;
}

.pick-drop-tittle-1::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 1px;
  -webkit-transform: translate(50%, -100%);
  transform: translate(50%, -100%);
  width: 1px;
  height: 59px;
  background-color: #46b925;
}

.pick-drop-rule-mark-1 {
  margin: 4px 0;
  color: #0a9967;
  font-size: 12px;
  line-height: 14px;
  padding: 0 0 0 20px;
}

.pick-drop-date-1 {
  margin: 8px 0;

  padding: 0 0 0 20px;
}

.pick-rule-details {
  margin: 16px;

  padding: 0 0 0 20px;
}

.car-modal-details {
  width: 800px !important;
}

.pick-up-info .ant-tabs-tab {
  font-size: 19px;
}

.address-book li {
  padding-bottom: 6px;
  font-size: 15px;
}

.second-datils-card {
  margin-top: 20px;
}

.pkg-details {
  outline: none;
  color: #bd0c21;
  font-weight: bold;
  margin-bottom: 0px;
  cursor: pointer;
}

.insurance-details-car {
  background-color: #f4f8fe;
  padding: 20px 24px;
}

.loading-size-de {
  border-radius: 4px;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #eee;
  margin-top: 20px;
}

.inclusions-1 {
  margin: 20px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 15px;
}

.inclusions-2 {
  margin: 20px;
}

.des-part {
  color: #bd0c21;
  cursor: pointer;
  flex-wrap: wrap;
}

.des-part::after {
  content: "";
  border-top: 1px solid #f5f5f5;

  margin: 20px 0;
}

.flex-new {
  flex-wrap: wrap;
  margin-top: 6px;
  display: block;
  margin-bottom: 7px;
}

.des-part-1 {
  color: #bd0c21;
  cursor: pointer;
  flex-wrap: wrap;
  box-sizing: initial;
  padding: 6px 12px;
  border-radius: 2px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
  color: #f58b1b;
  background-color: #fff3e0;
  cursor: pointer;
}

.fuel-car-de {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  padding: 10px 10px;
}

.offers-milege {
  margin-top: 25px;
}

.fuel-car-full {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #ff5722;
  padding: 10px 10px;
}

.fuel-car-full-1 {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #ff5722;
  padding: 10px 4px;
}

.gps-eng {
  margin-bottom: 0px;
  font-weight: 700;
}

.add-ons-bg {
  align-items: center;
  border-radius: 4px;
  background-color: #f4f8fe;
  padding: 10px 10px;
  border: 1px solid #f4f8fe;
  cursor: pointer;
}

.price-flex {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: end;
  align-items: flex-end;
}

.visa-ratings-act {

  overflow: hidden;
  padding: 10px 24px 0px;
  border-top: 1px solid #eee;
  background-color: white;

  .visa-image-wrapper {
    height: 150px;
    width: 158px;
  }

  .visa-image {
    height: 100%;
    width: 93%;
    object-fit: cover;
    margin-left: 10px;
    border: 3px solid #bd0c21;
    border-radius: 3px;

    &:hover {
      box-shadow: 1px 3px 4px grey;
    }
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .sub-heading {
    display: flex;
    max-height: 50px;
    overflow: auto;

  }

  .sub-heading,
  .sub-header-info,
  .extra-params {
    font-size: 14px;
  }

  .extra-params ul {
    padding-left: 20px;
    width: 50%;

    li {
      list-style: disc;
    }
  }

  margin-bottom: 10px;
  border-radius: 8px;
  padding: 0px 0px;
  box-shadow: 0 2px 5px 0 #13296833;

  .price-drops {
    text-align: center;
    padding: 0px 30px;
  }

  .debtndrops {
    padding-left: -5px;

    @include mobile {
      .btn {
        width: 264px;
      }
    }
  }

  .seats-chair {
    padding: 0px;
  }

  .visaGuide {
    font-size: 12px;
  }

  .visa-info-content {
    padding-left: 3rem;
  }
}

.seats-chair-certi {
  padding-top: 12px;

  img {
    display: block;
    margin: 0 auto;
  }

  p {
    margin-bottom: 0px;
    font-size: 12px;
    text-align: center;
  }
}

.sorting-car-v1 {
  border-radius: 8px;
  padding: 10px 10px;
  box-shadow: 0 2px 5px 0 #13296833;
  margin-bottom: 10px;
  margin-left: 0px;
  background-color: white;

  .sorting-menu-car {
    li {
      display: inline-block;
      padding: 0px 13px;
      font-size: 15px;
    }
  }
}

.cancleDetails {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #bd0c21;
}

.combined-results-container {
  padding-top: 80px;
  background: linear-gradient(to right, #bd0c21, #35459c);

  .visaSearch {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;

    padding-top: 15px;

    .flight_search_box {
      border-radius: 0;
    }

    h4 {
      font-size: 35px;
    }

    .ant-select-selector .ant-select-selection-search-input {
      height: 50px !important;
      color: black;
    }

    .ant-select-selector .ant-select-selection-placeholder {
      border: 0;
      background: none;
      font-weight: 600;
      font-size: 15px;
      line-height: 46px !important;
      outline: medium none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #000000;
    }

    .ant-select-selector {
      height: 50px !important;
      border-radius: 8px !important;

      border: 1px solid #f6f9fc !important;
      color: #bfbfbf;
    }

    .ant-select-selector .ant-select-selection-item {
      border: 0;
      background: none;
      font-weight: 600;
      font-size: 15px;
      outline: medium none;
      line-height: 46px !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #000;
    }

    .primary-btn {
      background: #bd0c21 !important;
      text-align: center;
      display: block;
      color: #fff;
      font-size: 16px;
      outline: 0;
      font-weight: 600;
      border: 1px solid #f6f9fc !important;
      border-radius: 40px !important;
      height: 50px;
    }
  }
}

@media screen and (max-width: 768px) {
  .visa-menu-12 {
    .sort-block {
      display: block !important;
    }

    .ant-radio-group-outline {
      display: inline-block !important;
    }

    .ml-4 {
      margin-left: 0rem !important;
    }
  }
}

.fbt-tabs-bg {
  .tabs-container {
    width: 80%;
    margin: 0 auto;

    @include tab {
      width: auto;
    }

    @include mobile {
      width: 100%;
    }
  }
}

.circle-container {
  position: absolute;
  z-index: 1;
  width: 135px;
  margin-left: 32%;
  /* Width of the circular cutout */
  height: 130px;
  /* Height of the circular cutout */
  border-radius: 50%;
  /* Create a circular shape */
  overflow: hidden;
  /* Hide overflow */
  box-shadow: 4px 3px 6px grey;
}

.carsel-wr {
  position: absolute;
  width: 200%;
  /* Double the width to accommodate sliding */
  height: 100%;
  animation: slideImage 30s linear infinite;
  /* Adjust animation duration */
}

.world-map-image {
  width: 100%;
  height: auto;
}

@keyframes slideImage {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}