.country-page-head{
    margin-top: 20px;
    color: #000000 !important;
    font-size: 30px;

}
.tab-bred{
    background: #e8ebf2 !important;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
}
.traveller-pay .ant-breadcrumb-link{
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85) ;
    padding: 0px 20px;

}
.cms-pages-width-1{
    max-width: 1140px !important;
    margin: auto;
}
.first-itinery{
    background: #e8ebf2;
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-radius: 10px;
    font-size: 20px;
}
.one-item-form{
    width: 96%;
}
.btn-continue{
    text-transform: uppercase;
    font-size: 15px;
    border-radius: 25px;
    background: linear-gradient(225.06deg,#3D7BD8 0%,#445E93 100%);
    box-shadow: 0 2px 4px 0 #C4D5F6;
    height: auto!important;
    padding: 6px 30px!important;
    line-height: 39px!important;
    color: #fff;
    float: right;
    margin-bottom: 10px;
}
.pkg-visa-price{
    background: #e8ebf2;
    width: 100%;
    float: left;
    padding: 15px 10px;
    margin-top: 5px;
    font-weight: 500;
    margin-left: 15px;
}
.pkg-visa-price h3{
    font-size: 19px;
    color: #445e93;
    margin: 0;
}


.visa-fare-box {
    font-size: 15px;
    color: #445e93;
    width: 100%;
    float: left;
    margin: 10px 0 0 0;
}
.visa-fare-box li {
    list-style: none;
    padding: 4px 0;
}
.visa-fare-box li span {
    float: right;
}


.visa-fare-total {
    font-size: 15px;
    color: #000;
    width: 100%;
    float: left;
    margin: 10px 0 0 0;
    border-top: 1px solid #a6b3cc;
}
.visa-fare-total li {
    list-style: none;
    padding: 4px 0;
}
.visa-fare-total li span {
    float: right;
    font-size: 18px;
    font-weight: 700;
}