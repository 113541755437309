@import "../../assets/variableStyles.scss";

.review-bottom-mobile {
    @include mobile {
        order: 1;
    }
}

.review-top-mobile {
    @include mobile {
        order: 0;
    }
}

.passenger-form {
    .form-group {
        @include mobile {
            flex-wrap: nowrap;
        }
    }
}

.checkout-container {

    // margin-top: 115px;
    .pax-heading {
        width: 100%;
        color: #35459c;
        padding-left: 7px;
        font-weight: 600
    }

    .inner-part-pass1 {
        border: 1px solid #35459c;
        padding: 10px;
        border-radius: 6px;
        margin-top: 12px;

        .heding1 {
            color: #efefef;
            font-weight: 500;
            font-size: 20px;
            margin-top: -26px;
            background: linear-gradient(to right, #bd0c21, #35459c);
            border-radius: 4px;
            width: fit-content;
            padding: 0 10px 0 10px;

            @include mobile {
                margin-top: -21px;
                font-size: 16px;
                width: auto;
            }
        }
    }
}

.checkout-body-sec {
    .checkout-sticky-part {
        padding: 5px 24px 24px;
        box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
        border-radius: 4px;
    }

    .checkout-top-part1 {
        width: 35%;

        @include tab {
            width: 100%;
        }

        @include mobile {
            order: 0;
        }
    }
}

.ssr-rev-1 {
    .ssr-label {
        padding-bottom: 9px;
    }

    .ssr-rev {
        display: flex;
        justify-content: space-between;

        @include mobile {
            justify-content: space-around;
        }

        .ssr-m {
            padding: 3px 6px;
            border: 1px solid #d9d9d9;
        }

        .ssr-b {
            padding: 3px 6px;
            border: 1px solid #d9d9d9;
        }
    }
}

.pop-ssr {
    border: 1px solid #35459c;
    border-radius: 4px;
    padding: 2px;

    .ssr-city {
        color: #bd0c21;
    }
}