@import "../../assets/variableStyles.scss";
.PopupModal {
  padding: 0 !important;

  width: 600px !important;
  background-image: linear-gradient(to right, #bd0c21, #35459c);
  background-repeat: no-repeat;
  background-size: cover;
  .ant-card,
  .ant-modal-content,
  .ant-card-body {
    background-color: transparent !important;
  }
  .ant-modal-content {
    box-shadow: none !important;
  }
  .ant-card-bordered {
    border: 0 !important;
  }
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
  h3 {
    color: #fff;
    font-size: 21px;
    text-align: center;
  }
  .ant-form-item {
    margin: 0;
  }
  .ant-card-body {
    padding: 0px !important;
  }
  .ant-modal-close-x {
    @include mobile {
      width: 30px !important;
      height: 30px !important;
      line-height: 20px !important;
    }
  }
}

.onSitePopup__modal {
  padding: 0 !important;
  width: 600px !important;
  background-image: linear-gradient(to right, #bd0c21, #35459c);
  background-repeat: no-repeat;
  background-size: cover;
  .ant-card,
  .ant-modal-content,
  .ant-card-body {
    background-color: transparent !important;
  }
  .ant-card-body {
    padding: 0 !important;
  }
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
  .ant-modal-content {
    box-shadow: none !important;
    .ant-card-bordered {
      border: 0 !important;
    }
    @include mobile {
      width: 100% !important;
    }
  }
  h3 {
    color: #fff;
    font-size: 21px;
    @include mobile {
      font-size: 18px;
    }
  }
  span.ant-avatar.ant-avatar-circle.ant-avatar-icon {
    line-height: 80px !important;
    @include mobile {
      height: 84px !important;
      width: 84px !important;
      line-height: 50px !important;
    }
  }
  .bordered {
    border-bottom: 2px dashed #fff;
  }
  .promoCode {
    border: 1px dashed;
    padding: 0px 15px;
  }
  .ant-form-item {
    margin: 0;
  }
  .ant-modal-body {
    @include mobile {
      padding: 14px !important;
    }
  }
  .ant-modal-close-x {
    @include mobile {
      width: 30px !important;
      height: 30px !important;
      line-height: 20px !important;
    }
  }
}
