.flight-enquiry-24 .ant-form-item {
  margin-bottom: 6px;
 
}
.flight-enquiry-24{
  padding-top: 100px;
}
.flight-env-21 {
  padding-top: 7%;
  text-align: end;
}

.slider-cards {
  div {
    align-items: center;
    height: 150px;
    text-align: center;
    display: flex !important;
    justify-content: center;

    margin: 0px 5px;
    p {
      margin: 0;
      color: #fff;
      padding: 0px 15px;
    }
  }
}
