.btob-profile-btn {
  background-color: #bd0c21 !important;
  border-color: #bd0c21 !important;
}
.agency-img-data .ant-upload.ant-upload-select-picture-card {
  height: 50px !important;
}
.my-pro-v3 {
  .ant-form-item {
    margin-bottom: 3px;
  }
  .remtype {
    margin-top: 4px;
    margin-bottom: 8px;
    font-size: 16px;
    color: #bd0c21;
  }
}
