@import "../../assets/variableStyles.scss";

.manage-markup-section {
  // background-color: #f6f7fb;
  padding: 20px;
  max-width: 1200px;
  // margin: 0 auto;
  @include mobile {
    padding: 70px 0px 20px 0px;
  }
  .heading {
    background: #fff;
    padding: 10px 20px;
    margin-bottom: 10px;
    /* padding-left: 25px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin-bottom: 0px;
    }
  }
  .card {
    margin-bottom: 30px;
    border: 0px;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 8px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    .card-header {
      background-color: #fff;
      border-bottom: none;
      padding: 30px;
      border-bottom: 1px solid #f6f7fb;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .card-body {
      padding: 30px;
      background-color: transparent;
    }
    .card-footer {
      background-color: #fff;
      border-top: 1px solid #f6f7fb;
      padding: 30px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .rows-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
      max-width: 150px;
    }
    h5 {
      @include mobile {
        font-size: 15px;
      }
    }
  }
  .role-table {
    margin-top: 20px;
    .table-padd {
      padding: 20px;
      @include mobile {
        padding: 10px;
      }
      .table-heading-count {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .footer-buttons .modal-footer {
        justify-content: flex-start !important;
      }
    }
    .table-card {
      padding: 20px;
      padding-top: 0px;
    }
  }
  .action-images {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 17px;
    img {
      max-width: 35px;
      max-height: 35px;
      margin-right: 15px;
      @include mobile {
        max-width: 25px;
        max-height: 25px;
        margin-right: 10px;
      }
    }
  }
}

#admin {
  max-width: 1800px;
  margin: 0 auto;
  .manage-markup-section {
    max-width: 100%;
  }
}

.header-class {
  background-color: #f8f8f8;
  color: #00164d;
}

.custom-row-class {
  :nth-child(even) {
    &:hover {
      background-color: #ccc;
    }
  }
}

.delete-modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  i {
    font-size: 30px;
    border: solid 1px red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    color: red;
  }
  h5 {
    margin-top: 15px;
  }
}
.delete-modal-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  .yes-btn {
    background: green;
    border: none;
    margin-right: 10px;
    padding: 7px 25px;
  }
  // .cancel-btn {

  // }
}
.action-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .info-icon {
    border: solid 1px #bd0c21;
    border-radius: 50%;
    padding: 1px 8px;
  }
  .edit-icon {
    font-size: 20px;
    color: #bd0c21;
  }
  i {
    color: #bd0c21;
  }
}
.delete-icon {
  color: red;
  i {
    color: red;
  }
}
.form-gender-types {
  display: flex;
  .female-type {
    margin-left: 20px;
  }
}
.mandatory-star {
  color: red;
}
.add-icon {
  background: #00164d;
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  color: #fff;
}
// #custom-react-table
.react-bootstrap-table table {
  table-layout: fixed;
  @include mobile {
    width: 1000px !important;
    overflow-x: scroll !important;
  }
}
.react-bootstrap-table-pagination {
  @include mobile {
    flex-wrap: nowrap !important;
  }
}
// }
.card-bt-gap {
  .ant-card-body {
    padding: 13px 25px;
    .card-add-heading {
      h5 {
        margin-bottom: 0;
      }
    }
  }
}
.admin-bc-level .ant-form-item {
  margin-bottom: 4px;
}
.flv-bc-21 .ant-modal-body {
  padding: 0px 10px 0px 10px;
}
