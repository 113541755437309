@import "../../../assets//variableStyles.scss";

.hotel-preview-container {
  .form-body {
    background-color: #eaebee;

    .form-body-container {
      max-width: 1100px;
      padding: 15px;
      margin: auto;

      .hotel-card-wrapper {
        border-radius: 8px;
        box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
        // margin: 15px 0;
        padding: 16px;

        .ant-card-body {
          padding: 0;
        }
      }
    }

    .book-pay-card {
      padding: 16px 24px;
      box-shadow: 0 2px 2px 0 #dcdee3;
      border: none;

      @include mobile {
        padding: 16px;
      }

      .book-pay-tc {
        display: flex;

        span {
          color: red;
        }

        p {
          margin-bottom: 0;
        }
      }

      .book-pay-offers {
        display: flex;

        p {
          font-size: 15px;

          a {
            &:hover {
              color: #bd0c21;
            }
          }
        }
      }

      .book-pay-btn {
        button {
          // background-color: #bd0c21;
          background: linear-gradient(to right, #320063, #0f76bb);
          color: #fff;
          border: none;
          font-size: 17px;
          font-weight: 600;
          padding: 8px 17px;
          height: 100%;

          @include mobile {
            font-size: 16px;
            padding: 10px 15px;
          }
        }

        .book-and-content {
          padding-top: 20px;

          p {
            font-size: 15px;
            color: #969dac;
          }
        }
      }
    }
  }
}
.fare-card-hot{
  border-radius: 8px;
  box-shadow: 1px 1px 5px grey;
}
.hotel-price-wrapper {
  .hdng {
    font-size: 20px;
    font-weight: 600;
    margin-top: -15px;
    border-bottom: 2px solid grey;
  }
}

.rm-pax-det {
  border: 1px solid #35459c;
  border-radius: 4px;
  padding: 4px;

  .rm-info {
    color: #ffff;
    background: linear-gradient(to right, #bd0c21, #35459c);
    padding: 1px 18px 1px 4px;
    max-width: fit-content;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 3px;
    margin-top: -4px;
    margin-left: -4px;
  }

  .passenger-info {
    thead {
      tr {
        background: #e9f0ff;
      }
    }

    th {
      color: #35459c;
    }
  }
}