/* .paymentFailure {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
}
.paymentFailure > h1.ant-typography {
  font-size: 50px;
  color: rgb(71, 71, 71);
}
.paymentFailure > .ant-typography {
  font-size: 35px;
  color: rgb(88, 88, 88);
} */

.pgFailureWrapper {
  max-width: 1140px;
  padding: 20px 15px;
  margin: 0 auto;
  padding-top: 100px;
  .ant-card {
    border-radius: 8px;
    box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
    .title {
      color: #bd0c21;
      font-size: 22px;
      font-weight: 700;
    }
    .subtitle {
      font-weight: 500;
      font-size: 16px;
      .mail {
        color: #35459c;
        font-weight: 600;
        cursor: pointer;
      }
      .tid {
        font-weight: 600;
      }
    }
    .subtitle1{
      font-weight: 600;
      .ti-id{
        color: #35459c;
      }
      .tid{
        color: #bd0c21;
      }
    }
  }
}
