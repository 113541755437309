@import "../../assets/variableStyles.scss";

.srch-btn-tr {
  margin-top: -31px;

  @include mobile {
    margin-top: 0;
  }

  .train-search-bt-1 {
    padding: 15px 30px 10px 30px;
height: 53px;
    @include mobile {
      padding: 0;
    }
  }
}
.imput{
  @include mobile{
    margin-top: -32px;
  }
}

.bus-search-wrapper {
  .from-return-date {
    border: 1px solid #d3d3d3 !important;
    border-radius: 0px !important;
  }

  .bus-panel-bg {
    .ant-form-item {
      margin-bottom: 0px !important;

      .ant-select-lg {
        border-right: 0px solid #d6d3d3;

        @include mobile {
          border-right: 0px;
          border-bottom: 1px solid #d6d3d3;
        }

        .ant-select-arrow {
          display: none;
        }

        .ant-select-selector {
          height: 65px !important;
          padding: 14px 11px;
          border-color: transparent !important;
          border: 0px solid !important;
          border-right-width: 0px !important;
          // border-radius: 50px;
          border: 1px solid #ffffff !important;
          color: #bfbfbf;
          // margin-top: 2%;

          .ant-select-selection-search-input {
            color: black;
            height: 65px !important;
          }

          .ant-select-selection-placeholder {
            border: 0;
            background: none;
            font-weight: 600;
            font-size: 15px;
            outline: medium none;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #000000;
          }

          .ant-select-selection-item {
            border: 0;
            background: none;
            font-weight: 600;
            font-size: 14px;
            outline: medium none;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #000;
          }
        }
      }

      .ant-picker-large {
        padding: 11px 11px 6.5px !important;
        height: 65px !important;
        border: none;
        border-right: 0px solid #d6d3d3 !important;
        border-radius: 0px;
        color: #bfbfbf;
        border: 1px solid #f6f9fc !important;

        @include mobile {
          border-right: 0px;
          border-bottom: 1px solid #d6d3d3;
        }

        .ant-picker-input {
          input {
            color: #000;
            font-weight: 600;
          }
        }

        .ant-picker-input input::placeholder {
          color: black;
        }
      }

      .ant-form-item-explain.ant-form-item-explain-error {
        color: #bd0c21 !important;
        margin-left: 5px;
      }
    }

    .destination {
      .ant-form-item {
        .ant-select-lg {
          .ant-select-selector {
            padding-left: 16px;

            @include mobile {
              padding-left: 11px;
            }

            .ant-select-selection-search {
              padding-left: 5px;

              @include mobile {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .bus-btn-pri {
    width: 100%;
    height: 65px;
  }

  .inputForm input {
    height: 65px !important;
  }

  .inputForm input::placeholder {
    color: black;
    font-weight: 600;
  }

  .car-search-option {
    display: flex;
    padding-bottom: 10px;
    justify-content: left;
    align-items: center;
    text-align: left;
    margin-left: 1%;

    .car-search-option-button {
      min-width: 96px;
      min-height: 18px;
      font-weight: 600;
      border-radius: 8px;

      @include mobile {
        padding: 0px 0px;
      }
    }

    .car-search-radio-button-col {
      font-weight: 600;

      span.ant-select-selection-placeholder {
        color: #000;
      }

      .ant-select {
        .ant-select-selector {
          border-radius: 20px;
        }
      }
    }
  }

  .car-search-radio-button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 1%;

    @include mobile {
      margin-left: 0%;
    }

    .car-search-radio-button-col {
      display: contents;
    }
  }

  .activeBtn {
    background: #bd0c21 !important;
    color: #fff !important;
    border-radius: 8px;

  }
}

.arrow-bus-inr {
  position: absolute;
  bottom: 0;
  top: 30%;
  right: -10px;
  z-index: 20;
  transition: all 0.5s ease 0s;
  cursor: pointer;

  @include mobile {
    top: 85%;
  }
}

.arrow-bus-inr:hover {
  transform: rotate(360deg);
}

// @media screen and (max-width: 768px) {
//   .bus-panel-bg .ant-picker {
//     width: 100%;
//   }

//   .bus-panel-bg .ant-select-selector {
//     border-bottom: 1px solid #d6d3d3 !important;
//     border-right: 0px solid #d6d3d3 !important;
//   }
// }
.ant-select-selector {
  padding-top: 25px;
  height: 100%;
}

.custom-mob-calendar {
  z-index: 999999999;

  .custom-mob-calendar-title {
    display: none;
  }

  @include mobile {
    height: 100%;
    width: 98%;

    margin: 0 auto;
    right: 0px !important;

    left: 0px !important;
    top: 0px !important;

    .custom-mob-calendar-title {
      display: block;
    }

    .ant-picker-panel-container {
      width: 100%;

      .ant-picker-panel {
        width: 85%;
        margin: 0 auto;
        display: block;
      }

      .ant-picker-date-panel,
      .ant-picker-content {
        width: 100%;
      }
    }
  }
}

.tooltip-button {
  display: flex;
  align-items: center;
  background: #fff;
  // border-radius: 15px;
  padding: 0 2px;
}

.transfer-search-btn {
  // width: 100%;
  line-height: 1.2;
  font-size: 16px !important;
}

.sec-bg-top {
  .ant-tabs-tab-btn {
    color: hsl(0, 0%, 100%);


  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: hwb(231 21% 39%) !important;
    pointer-events: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #35459c !important;
    font-weight: 600;
    text-shadow: 0 0 0.25px currentColor;
    transition: .2s;
  }

  // .top-routes-list{
  //   background: #505b985b;
  // }
}

.trans-rad {
  @include tab {
    margin-left: 16px;
  }
}
.from-to-inputs{
.droplocation-ffrm{
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{
margin-top: -10%;
}}}