.combined-booking-block {
    background-color: #eaeaea;
    padding: 20px;
    .booking-result-card {
        min-height: 100vh;
    }
    .booking-tabs-header {
        .ant-row {
            background-color: #05004e;
            padding: 15px 40px;
            color: #fff;
        }
        .ant-tabs-nav {
                .ant-tabs-tab {
                    &:hover {
                        color: #05004e !important;
                    }
                }
            .ant-tabs-tab-active {
                color: #05004e !important;
                font-weight: 500;
            }
        }
    }
}