@import "../../assets/variableStyles.scss";

.collapseCustomWhite {
  .cms-records-wrapper {
    .title {
      color: #294682;
      font-size: 18px;
      font-weight: bolder;
      margin-bottom: 5px;
    }
    .des-wrapper {
      // padding-left: 5px;
      font-size: 14px;
      p {
        font-size: 14px;
      }
    }
    .statement {
      font-size: 14px;
    }
  }
}

.ourteam {
  .ant-collapse-item {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(28, 43, 57, 0.1);
    border-radius: 3px;
    margin-top: 8px;
  }
}

.ourteam-values {
  background-image: url("../../assets/images/our-team.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 18.5em;
  width: 100%;
  margin-bottom: 15px;
}
.common-headings {
  /*  padding-top: 15%; */
  text-align: center;
  color: #bd0c21;
  @include mobile {
    font-size: 24px;
  }
}
.common-paras {
  text-align: center;
  color: #000;
}

.ourteam-bg {
  background: transparent !important;
  margin: 0 auto;
  // padding-top: 7%;
}
.cms-pages-width {
  max-width: 1100px !important;
  margin: auto;
  padding: 0 15px;
 }
