@import "../../assets/variableStyles.scss";
.Careers-new {
  background-image: url("../../assets/images/career-new.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 18.5em;
  width: 100%;
  margin-bottom: 15px;

  // Responsive adjustments
  @media (max-width: 768px) {
    height: 12em;
  }
}

.career-form-container {
  max-width: 80%px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff; 

  .career-form-container-1{
    max-width: 700px;
    margin-left: 330px;

    @include tab{
      max-width: 508px;
      margin-left: 115px;
    }
    @include minitab{
      max-width: 508px;
      margin-left: 124px;
    }

    @include mobile{
      margin-left: 0px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px; 
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-control {
    text-align: left;
  }

  .ant-input,
  .ant-select-selector,
  .ant-btn {
    // width: 100%;
  }

  .ant-btn {
    margin-top: 15px; // Add some spacing between button and form items
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    max-width: 100%;
  }
}
.carrers-btn{
  margin-left: 938px;
}
@media screen and(max-width:1024px){
  .carrers-btn{
    margin-left: 540px;
  }
}
@media screen and(max-width:768px){
  .carrers-btn{
    margin-left: 464px;
  }
}
@media screen and(max-width:820px){
  .carrers-btn{
    margin-left: 498px;
  }
}

@media screen and(max-width:767px){
  .carrers-btn{
    margin-left: 0px;
  }
}