.visa-Type-Slider {
  max-width: 1000px;
  margin: 0 auto;
  .visatype-slider-card {
    height: 150px;
    justify-content: center;
    width: 150px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    padding: 2px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    background-blend-mode: overlay;
    background-color: #00000063;
    p {
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      margin: 0;
      color: #fff;
      padding: 0px 5px;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    color: #000 !important;
  }

  .slick-prev:before {
    content: "\f053" !important;
    font-family: "Open Sans"  !important;
  }
  .slick-prev {
    left: -50px;
  }
  .slick-next {
    right: -50px;
  }
  .slick-next:before {
    content: "\f054" !important;
    font-family: "Open Sans" ;
  }
}
