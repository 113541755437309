@import "./assets/variableStyles.scss";
@import "../src/components/HomeCarousel/Slick_CarouselStyle/slick";
@import "../src/components/HomeCarousel/Slick_CarouselStyle/slickTheme";
.translated-rtl {
  direction: rtl;
}
.translated-ltr {
  direction: ltr;
}
.goog-logo-link {
  display: none !important;
}
iframe {
  visibility: hidden !important;
}
body {
  top: 0px !important;
  .links:hover {
    text-decoration: underline !important;
  }
  .links {
    padding-top: 5px;
  }
  .goog-text-highlight {
    background-color: unset !important;
    box-shadow: unset !important;
    -webkit-box-shadow: unset !important;
  }
  div#goog-gt-tt {
    display: none !important;
  }
}
div#google_translate_element {
  height: 26px !important;
  overflow: hidden !important;
}
.banner_ads {
  margin-top: 16px;
  margin-bottom: 16px;
  img {
    width: 100%;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
  }
}

.mob-M-Top {
   background-color: #eaeaea;
  @include mobile {
    margin-top: -20px;
  }
  // @include smmobile {
  //   margin-top: 53px;
  // }
  .ourteam-bg{
    // border: 2px solid red;
    padding-top: 8%;
    @include mobile{
      padding-top: 32%;
    }
  }
}

.customscroll {
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 16px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  .ant-collapse-content {
    max-height: 240px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 5px;
  }
}

.pointer_cursor {
  cursor: pointer !important;
}

.airline-matrix-table {
  .ant-table-thead {
    th {
      cursor: pointer;
    }
  }
}

.checkout_details_card {
  .trip-details {
    .flight-timings {
      width: 100%;

      .details-btn {
        background-color: #bd0c21;
        border-color: #bd0c21;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
        justify-content: center;
        padding: 4px;
        border-radius: 3px;

        .details-text {
          display: block;
          color: white;
          width: 100%;
          height: 100%;
          text-align: center;
          margin-left: 0px;
        }
      }
    }
  }
}
.full_height {
  height: 100% !important;
}

.airpot_names_dropdown {
  width: 100% !important;
  max-width: 350px;
  b {
    color: #bd0c21;
  }

  .child {
    margin-left: 12px !important;
  }
}

.multicity {
  .ant-form-item-label {
    text-align: left;
  }
  .minus_multicity {
    padding: 35px 0 30px 0;
    position: absolute;
    right: -30px;
    z-index: 6;
  }

  .ant-form-item .ant-col {
    width: 100%;
  }
}
.ant-notification-bottomRight {
  bottom: 5px !important;
  left: 5px;
  margin-right: 5px !important;
  max-width: 100% !important;
  z-index: 9999988 !important;

  .cookies-notification {
    width: 100%;
    max-width: 98%;
    background-color: #00164d;
    color: #fff;
    margin: 0 5px 5px 5px;
    @include mobile {
      padding: 15px 10px !important;
    }
    .ant-notification-notice-message {
      display: none;
    }
    .ant-notification-notice-description {
      font-size: 12px;
      max-width: 80%;
      @include mobile {
        max-width: 70%;
      }
    }
    .cookies-btn {
      background-color: #bd0c21;
      color: #fff;
      font-weight: 600;
    }
  }
}

.promo_added_fee {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 16px;
}

.fee_added_group {
  display: flex;
  justify-content: space-between;
}

.collapseCustomWhite {
  .ant-collapse-item {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgba(28, 43, 57, 0.1);
    border-radius: 3px;
    margin-top: 8px;
  }
  .promo {
    @include mobile {
      height: 150px;
      margin-bottom: 16px;
    }
  }
}

.successModalBox {
  width: 600px !important;
  .ant-modal-close-x {
    height: 84px;
    color: #4e4e4e;
  }
  .ant-modal-header {
    background-color: #fff;
    .ant-modal-title {
      color: #BD0C21;
      font-size: 24px;
      padding: 15px 0;
      text-align: center;
      @include mobile {
        font-size: 18px;
        padding: 5px 20px 0 0;
      }
    }
  }
  .ant-modal-body {
    padding: 24px 10px;
    .sucessModal {
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 1px solid #f0f0f0;
      .modalIcon {
        font-size: 58px;
        margin-bottom: 10px;
        color: #1cad61;
        @include mobile {
          font-size: 48px;
        }
      }
      h6 {
        margin-bottom: 20px;
        color: #1cad61;
        font-weight: 600;
        @include mobile {
          font-size: 14px;
        }
      }
      p {
        margin-bottom: 5px;
        @include mobile {
          font-size: 12px;
        }
      }
    }
    .socialLinks {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      span {
        width: 60px;
        color: #9a9a9a;
        @include mobile {
          width: 40px;
        }
        .anticon {
          font-size: 18px;
        }
      }
      .sharetext {
        @include mobile {
          display: block;
          text-align: center;
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
}
.airline_logo_coloumn {
  img {
    width: 42px;
    margin-left: 15px;
  }
}

.markup-modal {
  .ant-input-number {
    .ant-input-number-handler-wrap {
      display: none !important;
      pointer-events: none;
    }
  }
}

// .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
//   border-right-width: 0px !important;
// }

.promo-cp-coupons {
  .ant-form-item {
    margin-bottom: 0px;
  }
  .inline-cp-promo {
    display: flex;
    .promo-key-cp {
      border: 1px dashed #bd0c21;
      padding: 6px 6px 6px 6px;
      color: #bd0c21;
      font-size: 12px;
      margin-bottom: 0px;
      margin-left: 4px;
      font-weight: 600;
      border-radius: 20px;
    }
    .save-cp-offer {
      color: #35459c;
      font-size: 13px;
      margin-left: 14px;
      margin-bottom: 0px;
      padding-top: 8px;
    }
  }
  .promo-percentage-cp {
    padding-left: 25px;
    padding-top: 5px;
    p {
      color: #757575;
      margin-bottom: 0px;
      font-size: 13px;
    }
  }
}
.background-primary-orange {
  background: #bd0c21 !important;
}
.showmore-wrapper > p {
  margin-bottom: 0;
}

.showmore-span {
  cursor: pointer;
  color: #bd0c21;
  font-size: 13px;
  padding-bottom: 2px;
  border-bottom: 1px dashed #bd0c21;
}
