.cartypes-value {
  width: 520px !important;

  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-modal-header {
    background: #ab744a !important;
    border-radius: 15px;
  }
}
