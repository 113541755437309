
.dubai-bg-img{

    background-image: url("../../../assets/images/visa/dubai-one.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 384px;
        width: 100%;
        margin-bottom: 0px;

}
.dubai-layout{
    background: transparent !important;
}
.dubai-layout-1{
    background-color: transparent !important;
}
.online-visa-form{
    text-align: center;
    padding: 0.8%;
    background-color: #FFF;
    border-radius: 15px;
   width: 26%;
    color: #587ec0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    
   
  
}

.online-visa-form-1{
    text-align: center;
    padding: 0.8%;
    background-color: #FFF;
    border-radius: 15px;
   width: 26%;
    color: #587ec0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    display: flex;
   
}

.hero-text-block {
    width: 44%;
    margin-left: 4%;
    margin-right: 4%;

}


.hero-text-block p {
    font-size: 100%!important;
    text-align: left;
    color: #445d93;
    margin: 0 auto;
    font-weight: 200;
}
.hero-text-block span {
    font-size: 180%!important;
    text-align: left;
    color: #445d93;
    margin: 0 auto;
    font-weight: 200;
}
.hero-text-block2 {
    width: 35%;
    margin-left: 4%;
    margin-left: 10%;
}
.hero-text-block2 p {
    font-size: 100%!important;
    text-align: left;
    color: #445d93;
    margin: 0 auto;
    font-weight: 200;
}
.hero-text-block2 span {
    font-size: 180%!important;
    text-align: left;
    color: #445d93;
    margin: 0 auto;
    font-weight: 200;
}
.card-new-visa{

}
.visa-type{
  
    font-size: 15px;
    margin: 0!important;
    float: left !important;
}
.visa-type li {
    padding: 6px 0;
    float: left;
    width: 100%;
}
.visa-type li span {
    float: right;
}
.card-inu .ant-card-body{
    padding: 10px !important;
}
.card-inu .ant-card-head{
    width: 100%;
   
    background: #e8ebf2;
    color: #000;
    
    color: #000;
}
.card-inu {
    background-color: #fff;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    border-radius: 2px;
    width: 90%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.visa-days-pol{
    margin-top: 20px;
}
.list-style-visa li{
    padding-bottom: 10px;
}
.visa-includes{
    font-size: 20px;
    color: #000;
    font-weight: 600;
}
.visa-img-process{
    text-align: center;

}
.visa-data-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.form-elements{
    padding: 8px!important;
    background: linear-gradient(225.06deg,#3D7BD8 0%,#445E93 100%);
    width: 325px;
   margin-top: 24%;
}
.online-visa-form-new{
    color: #fff;
    padding-bottom: 15px;
    text-align: center;
    padding-top: 15px;
}
.visa-apply-btn{
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    border-radius: 25px;
    background: linear-gradient(225.06deg,#3D7BD8 0%,#445E93 100%);
    box-shadow: 0 2px 4px 0 #C4D5F6;
    height: auto!important;
    padding: 6px 30px!important;
    line-height: 39px!important;
}
.whatsapp-btn{
    margin-top: 5px;
}
.call-buttton{
    width: 100%;
}
.all-detail-visa{
    font-size: 20px;
   
    color: #FFFFFF !important;
    text-align: center;
    margin-bottom: 5px !important;
}

@media screen and (max-width: 768px) {
    .online-visa-form-1 {
       
        width: 56% !important;
    }
    .online-visa-form {
        
        width:43%;
    }
}

