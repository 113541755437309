@import "../../../assets/variableStyles.scss";

.trip-contents {
  // padding: 10px 15px;
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;

  // padding-left: 50px;
  @include mobile {
    padding-left: 5px;
  }

  &:hover {
    background: var(--leg-hover-color, rgba(234, 235, 238, 0.6));
  }

  input {
    margin-right: 10px;
  }
}

// .airline-img {
//     max-width: 55px;
//     height: 28px;
// }
.trip-content-left {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

.trip-arr-dep {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;

  @include mobile {
    flex-direction: column;
  }
}

.timings {
  i {
    color: #1c2b39;
    font-size: 15px;
    padding: 1px 5px;
  }
}

.duration {
  padding-left: 20px;
  text-align: center;

  @include mobile {
    padding-left: 5px;
  }

  i {
    @include mobile {
      display: none;
    }
  }
}

.show-more-btn {
  padding: 4px 4px 0px 80px;

  @include mobile {
    padding: 4px 4px 0px 26px;
  }

  button {
    border: solid 1px #bd0c21;
    color: #bd0c21;
    line-height: 25px;
    font-size: 12px;
    font-weight: 700;

    &:hover {
      background: none;
      color: #bd0c21;
      font-weight: 700;
    }

    i {
      color: #bd0c21;
      font-weight: 700;
    }
  }
}

.share-section {
  font-size: 17px;
  color: #007baa;
  display: block;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-top: 3px;

  span {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.currencyType {
  color: #969dac;
  font-size: 15px;
  letter-spacing: 1px;
  padding-right: 4px;

  @include mobile {
    font-size: 15px;
  }
}

.netfare {
  font-size: 15px;
  letter-spacing: 1px;
  margin-bottom: 2px;
}

.different-amenities {
  font-size: 16px;
  color: rgb(29, 160, 35);

  // margin-left: 100px;
  // margin-left: 140px;
  @include mobile {
    // margin-left: 20px;
  }
}

/* Tooltip-styles start */
// .amenities-tool-tip {
//     @include mobile {
//         display: none;
//     }
// }
.ament-tooltip {
  padding-left: 40px;
}

.amenities-tooltip .amenities-tooltip>.tooltip-inner {
  // max-width: 100%;
  min-width: 450px !important;

  @include mobile {
    max-width: 300px !important;
  }
}

.ant-tooltip {
  max-width: auto !important;

  //   left: 200px !important;
  @include mobile {
    max-width: 300px !important;
  }
}

// .ant-tooltip-content {
//     min-width: 450px !important;
//     background: #0e0e0e !important;
//     @include mobile {
//         max-width: 300px !important;
//     }
// }

.tooltip-content {
  display: flex;
  justify-content: center;
  padding: 20px 20px !important;

  //   min-width: 450px !important;
  .flight-amenities {
    padding: 0px 5px;

    .flight-src-dest {
      h6 {
        color: #fff;
        padding-bottom: 10px;
      }
    }

    .amenity-types {
      p {
        line-height: 1.4;
        font-size: 12px;

        i {
          padding-right: 6px;
        }
      }

      .aminities-color {
        color: #bd0c21;
        font-size: 12px;
      }
    }
  }
}

/* Tooltip-styles end */

.trip-total-details {
  @include mobile {
    order: 1;
  }
}

.trip-choose-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-left: solid 1px #ccc;

  @include mobile {
    order: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    padding: 0px 30px;
  }
}

/*********** Antd styles ********/
.flight-results-container {

  // height: 100%;
  .ant-card-body {
    padding: 0;
  }

  .ant-row {
    @include mobile {
      flex-wrap: wrap-reverse;
    }
  }

  .flight-trip-details {
    border-right: 1px solid #ccc;

    @include mobile {
      border: none;
    }

    // padding: 15px;
    background-image: linear-gradient(180deg, #fafcfd, #fff);
    border-radius: 8px;

    .itinary-details {
      display: flex;

      @include mobile {
        display: block;
      }

      .itinary-flight-name {
        width: 25%;

        @include mobile {
          width: 78%;
          margin-bottom: 12px;
        }

        img {
          width: 50px;

          @include mobile {
            width: 35px;
            align-items: center;
          }
        }
      }

      .fromTo {
        width: 50%;
        margin-top: 2%;

        @include tab {
          width: 80%;
          margin-top: 3%;
        }

        @include mobile {
          flex: 1;
          width: 100%;
          padding-top: 10px;
          margin-top: 14%;
        }

        .cities-from-to {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // align-items: center;
          position: relative;

          @include mobile {
            width: 94%;
          }

          .loc_wrapper,
          .terminal_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 10px;
            font-weight: 600;

            @include mobile {
              p {
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }

  .code-line {
    width: 50%;
    height: 2.4px;
    background: #bd0c21;
    margin: auto;

    @include mobile {
      font-size: 12px;
      padding: 0px 15px;
      align-items: center;
      text-align: center;
      width: 10%;
    }
  }

  .flight-class-details {
    width: 25%;
    text-align: end;

    @include mobile {
      width: fit-content;
      position: absolute;
      top: 18px;
      right: 12px;
    }
  }
}

/******************* Flight Choose button section ***********/
.price-tab-fli {
  position: relative;
  z-index: 3;
}

.choose-btn-wrapper {
  background: #fff;
  margin-bottom: 10px;

  height: 100%;

  background-image: linear-gradient(180deg, #fafcfd, #fff);
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
  border-right: 1px solid #ccc;
}

.choose-btn-block {
  text-align: center;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mobile {
    flex-direction: row;
    width: 100%;
    text-align: start;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
  }

  .choose-btn-price {
    margin-bottom: 10px;

    @include mobile {
      margin-bottom: 0;
    }
  }
}

.choose-btn-section {
  @include mobile {
    order: 1;
  }
}

.btn-section {
  @include mobile {
    display: flex;

    .share-section {
      margin-right: 10px;
    }
  }
}

.choose-button {
  margin: 0;

  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 23px;
  }
}

/******************* Flight Timings ***********/
.flight-timings {
  display: flex;
  align-items: center;
  margin-left: 0px;
  padding: 2px 0;
  justify-content: space-between;

  @include mobile {
    align-items: baseline;
  }

  .plane-timing-details {
    display: flex;
    width: 100%;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  @include mobile {
    margin-left: 0;
  }

  .plane-timings {
    // width: 100%;
    display: flex;

    @include mobile {
      margin-bottom: 10px;
    }

    .timings-container {
      margin-right: 20px;

      @include mobile {
        margin-right: 0;
      }
    }

    ul {
      display: flex;
      align-items: center;

      li {
        margin-right: 5px;

        p {
          margin-bottom: 0;
          font-size: 13px;
          font-weight: bold;

          // @include mobile {
          //   font-size: 14px;
          // }
        }
      }
    }
  }

  .travel-time {
    display: flex;
    align-items: center;
    width: auto;

    p {
      margin-bottom: 0;
      margin-left: 5px;
      display: flex;
      align-items: center;
      color: #ccc;

      @include mobile {
        font-size: 12px;
      }

      span {
        margin-right: 5px;
        font-size: 20px;

        @include mobile {
          font-size: 15px;
          margin-right: 0;
        }
      }
    }
  }

  .facility-icons {
    width: 35%;

    span {
      display: flex;

      p {
        margin-right: 15px;
        margin-bottom: 0;
        color: #bd0c21;
        font-size: 20px;

        @include mobile {
          font-size: 15px;
        }
      }
    }
  }

  .details-btn {
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: flex-end;

    p {
      margin-bottom: 0;
      margin-left: 5px;
    }

    .details-text {
      font-size: 13px;
      color: #35459c;
      margin-left: 15px;
      cursor: pointer;
    }

    .details-bag-icon {
      font-size: 20px;
      display: flex;
      align-items: center;
    }
  }
}

.flight-details-modal {
  width: 900px !important;

  .ant-modal-header {
    .ant-modal-title {
      color: white;
    }
  }

  .ant-modal-body {
    padding: 0;
    max-height: 500px;
    overflow-y: scroll;
  }

  .ant-modal-footer {
    display: none;
  }

  .all-times-local {
    padding: 16px 24px;
    border-bottom: 1px solid #ccc;

    @include mobile {
      text-align: center;
      padding: 0 15px 15px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .from-to-modal-details {
    padding: 16px 24px;

    @include mobile {
      padding: 0;
      margin-bottom: 10px;
    }
  }

  .more-details-row {
    align-items: center;

    @include mobile {
      align-items: stretch;
      flex-direction: column;
    }

    .cities-names {
      @include mobile {
        width: 100%;
      }
    }

    .more-details-list {
      &:nth-child(2) {
        @include mobile {
          text-align: end;
        }
      }

      p {
        &:nth-child(1) {
          font-size: 25px;
          text-transform: uppercase;
          color: #00164d;
          margin-bottom: 0;

          @include mobile {
            font-size: 20px;

            span {
              display: block;
            }
          }
        }

        &:nth-child(2) {
          margin-bottom: 0;
          font-size: 12px;

          @include mobile {
            span {
              display: block;
            }
          }
        }
      }
    }

    .more-details-list-to {
      @include mobile {
        text-align: end;
      }
    }

    .more-details-line {
      height: 2px;
      background-color: #bd0c21;
      margin: auto;
      margin-left: -15px;

      @include mobile {
        margin-left: 0;
      }
    }

    .trip-duration-time {
      @include mobile {
        display: flex;
        align-items: center;
        align-self: center;
      }

      p {
        &:nth-child(1) {
          color: #969dac;
          margin-bottom: 0;
          font-size: 12px;

          @include mobile {
            margin-right: 10px;
          }
        }

        &:nth-child(2) {
          color: #00164d;
          margin-bottom: 0;
        }
      }
    }
  }

  .total-flight-details {
    padding: 16px 24px 16px 60px;
    background-color: #f6f7f8;

    @include mobile {
      padding: 15px 15px 15px 22px;
    }

    .to-fro-flight-details {
      display: flex;
      position: relative;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid #bd0c21;
        position: absolute;
        left: -33px;
        top: 4px;

        @include mobile {
          left: -16px;
        }
      }

      .from-city-flight-details {
        margin-right: 25px;

        @include mobile {
          display: flex;
          margin-right: 0;
        }
      }
    }

    .collapsed-card {
      padding: 25px 0;
      position: relative;

      @include mobile {
        padding: 10px 0;
      }

      &::before {
        content: "";
        height: 100%;
        position: absolute;
        background-color: #bd0c21;
        width: 3px;
        left: -29px;
        top: -2px;

        @include mobile {
          left: -12px;
          top: -24px;
        }
      }

      .ant-card-body {
        padding: 0;
      }

      .ant-collapse {
        border: none;
      }

      .ant-collapse-item {
        border: none;
      }

      .ant-collapse-header {
        background-color: white;
      }

      .ant-collapse-content-box {
        @include mobile {
          padding: 0 10px 10px;
        }
      }
    }
  }
}

.flight-details-modal1 {
  width: 900px !important;

  .ant-modal-header {
    .ant-modal-title {
      color: white;
    }
  }

  .ant-modal-body {
    padding: 0;
    max-height: 500px;
    // overflow-y: scroll;

    @include mobile {
      max-height: fit-content;
    }
  }

  .ant-modal-footer {
    display: none;
  }

  .all-times-local {
    padding: 16px 24px;
    border-bottom: 1px solid #ccc;

    @include mobile {
      text-align: center;
      padding: 0 15px 15px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .from-to-modal-details {
    padding: 16px 24px;

    @include mobile {
      padding: 0;
      margin-bottom: 10px;
    }
  }

  .more-details-row {
    align-items: center;

    @include mobile {
      align-items: stretch;
      flex-direction: column;
    }

    .cities-names {
      @include mobile {
        width: 100%;
      }
    }

    .more-details-list {
      &:nth-child(2) {
        @include mobile {
          text-align: end;
        }
      }

      p {
        &:nth-child(1) {
          font-size: 25px;
          text-transform: uppercase;
          color: #00164d;
          margin-bottom: 0;

          @include mobile {
            font-size: 20px;

            span {
              display: block;
            }
          }
        }

        &:nth-child(2) {
          margin-bottom: 0;
          font-size: 12px;

          @include mobile {
            span {
              display: block;
            }
          }
        }
      }
    }

    .more-details-list-to {
      @include mobile {
        text-align: end;
      }
    }

    .more-details-line {
      height: 2px;
      background-color: #bd0c21;
      margin: auto;
      margin-left: -15px;

      @include mobile {
        margin-left: 0;
      }
    }

    .trip-duration-time {
      @include mobile {
        display: flex;
        align-items: center;
        align-self: center;
      }

      p {
        &:nth-child(1) {
          color: #969dac;
          margin-bottom: 0;
          font-size: 12px;

          @include mobile {
            margin-right: 10px;
          }
        }

        &:nth-child(2) {
          color: #00164d;
          margin-bottom: 0;
        }
      }
    }
  }

  .total-flight-details {
    padding: 16px 24px 16px 60px;
    background-color: #f6f7f8;

    @include mobile {
      padding: 15px 15px 15px 22px;
    }

    .to-fro-flight-details {
      display: flex;
      position: relative;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid #bd0c21;
        position: absolute;
        left: -33px;
        top: 4px;

        @include mobile {
          left: -16px;
        }
      }

      .from-city-flight-details {
        margin-right: 25px;

        @include mobile {
          display: flex;
          margin-right: 0;
        }
      }
    }

    .collapsed-card {
      padding: 25px 0;
      position: relative;

      @include mobile {
        padding: 10px 0;
      }

      &::before {
        content: "";
        height: 100%;
        position: absolute;
        background-color: #bd0c21;
        width: 3px;
        left: -29px;
        top: -2px;

        @include mobile {
          left: -12px;
          top: -24px;
        }
      }

      .ant-card-body {
        padding: 0;
      }

      .ant-collapse {
        border: none;
      }

      .ant-collapse-item {
        border: none;
      }

      .ant-collapse-header {
        background-color: white;
      }

      .ant-collapse-content-box {
        @include mobile {
          padding: 0 10px 10px;
        }
      }
    }
  }
}

.acc-body {
  .acc-body-section {
    display: flex;
    padding: 7px 2px;
    align-items: baseline;

    @include mobile {
      font-size: 12px;
    }

    .icon {
      margin-right: 7px;
      color: #bd0c21;
    }

    p {
      color: #bd0c21;
    }

    &:nth-child(1) {
      padding-right: 25px;

      @include mobile {
        padding-right: 0;
      }
    }

    .refundable {
      color: #bd0c21;
      padding-left: 12px;
    }

    .non-refundable {
      color: red;
      padding-left: 12px;
    }
  }

  .seat-sec {
    i {
      padding-right: 7px;
      color: #505c75;
    }

    p {
      color: #505c75;
    }
  }

  .flight-class {
    .ec-class {
      padding-left: 12px;
    }

    span {
      font-weight: 700;
    }
  }
}

.tooltip-data {
  h4 {
    font-size: 14px;
    font-weight: bold;
    color: #313131;
  }

  p {
    font-size: 12px;
    color: #35459c;
    margin-bottom: 0;
  }
}

.ant-tooltip-content {
  min-width: 100% !important;
  border-radius: 10px;

  .ant-tooltip-inner {
    padding: 10px 15px;
    background: #f3f0f0e2 !important;
  }
}

.pointer_cursor {
  p {
    color: #35459c;
    margin-bottom: 0px;
  }
}

.ant-tooltip {
  max-width: 100% !important;
}

.luggage-tooltip {
  p {
    color: #35459c;
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;

    .ant-icon,
    i {
      font-size: 18px;
      margin-right: 5px;
    }

    &:nth-child(1) {
      color: #bd0c21;
    }

    &:nth-child(2) {
      color: rgb(63, 62, 62);
    }
  }
}

.collapse-header {
  .flight-icon {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      height: 40px;
      width: 40px;
    }

    .flight-names-block {
      p {
        font-size: 12px;
        margin-bottom: 0;

        &:nth-child(1) {
          font-size: 20px;

          @include mobile {
            font-size: 14px;
          }
        }
      }
    }
  }

  .ant-row {
    align-items: center;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .modal-flight-icon {
    i {
      margin-right: 10px;
      font-size: 18px;

      @include mobile {
        font-size: 15px;
      }
    }

    span {
      @include mobile {
        font-size: 13px;
      }
    }
  }

  .timer-flight-icon {
    span {
      margin-right: 10px;

      @include mobile {
        font-size: 13px;
      }
    }

    .icon {
      font-size: 18px;

      @include mobile {
        font-size: 15px;
      }
    }
  }
}

.combined-view {
  //   margin-bottom: 10px;
  height: 100%;

  .flight-trip-details {
    padding: 15px;
  }
}

/*********** Seperate View ******/
.seperate-view {
  margin-bottom: 10px;

  .right-flight-details {
    border-bottom: 1px solid #ccc;
    padding: 15px;

    &:last-child {
      border: none;
    }
  }
}

.break-journey-city {
  border: 1px solid #35459c;
  position: relative;
  margin: 0 5px;
  flex: 1;

  .inner_wrapper {
    position: absolute;
    width: 100%;
    text-align: center;

    .stop_points {
      display: inline-block;
      margin: 5px;
    }
  }

  .code-line-break {
    height: 7px;
    width: 7px;
    background-color: #bd0c21;
    border-radius: 50%;
    margin: auto;
    margin-top: 12px;

    &::before {
      content: "";
      background-color: #bd0c21;
      width: 20%;
      // top: 22px;
      height: 2px;
      left: 50px;
      position: absolute;

      @include mobile {
        width: 10%;
        left: 45px;
      }
    }

    &::after {
      content: "";
      background-color: #bd0c21;
      width: 20%;
      // top: 22px;
      height: 2px;
      right: 47px;
      position: absolute;

      @include mobile {
        width: 10%;
        right: 45px;
      }
    }
  }

  .break-city-name {
    font-size: 12px;
    text-transform: uppercase;

    &::before {
      position: absolute;
      height: 7px;
      top: -7px;
      width: 7px;
      margin: 0 2px;
      background-color: #bd0c21;
      border-radius: 50%;

      border: 4px solid white;
      box-sizing: content-box;
      content: "";
    }
  }
}

.change-timer {
  background-color: #eaebee;
  padding: 24px 60px;
  display: flex;
  align-items: center;

  .change-text {
    color: #e0760b;
    font-weight: 600;
    margin-right: 30px;
    display: flex;
    align-items: center;

    span {
      font-size: 20px;
    }

    .icon-text {
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  .time-for-interchange {
    font-size: 13px;
  }
}

.combined-flight-results-container {
  height: 100%;

  .combined-flight-card {
    height: auto;

    .ant-card-body {
      height: 100%;

      .ant-row {
        height: 100%;
      }

      .flight-trip-details {
        height: 100%;
      }
    }
  }
}

.share-modal {
  width: 290px !important;
  border-radius: 10px;
}

.share-link-icons {
  display: flex;

  .common-share-icons {
    margin: 15px 30px;
    text-align: center;

    .anticon {
      font-size: 25px;
      color: #bd0c21;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.flight-infoModal {
  text-align: center;

  .ant-modal-body {
    padding: 18px !important;
    text-align: center;
  }

  .ant-btn {
    margin-top: 15px;
  }
}

/* Dec-10-2020 */
.itinary-flight-name p {
  margin-bottom: 0px !important;
}

.combined-flight-results-container .ant-card-bordered {
  background-color: rgba(0, 0, 0, 0.03) !important;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
  border: 0 solid #f0f0f0 !important;
}

.share-price-mail {
  display: block;
}

/*---22/07/2020---*/
.token-number-flught {
  display: flex;

  p {
    white-space: nowrap;
  }
}

.flight-syt-time {
  position: absolute;
  top: 62px;
  left: 38%;

}

.details-bag-icon {
  display: flex;
  margin-bottom: 0px;
  line-height: 13px;
  align-items: center;
  margin-bottom: 0;
  color: #bd0c21;
  font-size: 13px;
}

.details-bag-icon {
  position: absolute;
  top: 77px;
  right: 14px;
}

.refundText {
  .refund {
    border: 1px solid #35459c;
    color: #35459c;
    // background: #8591d5b6;
    padding: 0px 14px;
    border-radius: 15px;
    margin: 0;
  }

  .Nrefund {
    border: 1px solid #bd0c21;
    color: #bd0c21;
    // background: #f44b5fbf;
    padding: 0px 14px;
    border-radius: 15px;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .itinary-details {
    display: block t;
  }

  .refundText {
    position: absolute;
    top: 72px;
    right: 146px;
    font-size: 11px !important;
  }

  .flight-syt-time {
    position: relative !important;
    left: 25% !important;
    top: 0 !important;
  }

  .details-bag-icon {
    top: 72px !important;
  }

  .flight-timings .facility-icons {
    width: auto !important;
  }

  .flight-timings {
    margin-top: 10px;
    margin-bottom: -20px;
  }
}

.promoData {
  // text-align: center;
  padding: 5px 0;
  padding-left: 10px;
  // background:   linear-gradient(to right, #bd0c21, #35459c);
  background: linear-gradient(to right, #320063, #0f76bb);
  color: #fff;
  position: relative;
  z-index: 1;
}

.FarDetailsCollapse {
  background: #eaeaea;
  // border-bottom: 2px solid #bd0c21;
  margin-top: -24px;
  position: relative;
  z-index: 0;
  // .ant-collapse-content{
  //   background: linear-gradient(to bottom, red, #ffffff00);
  // }
}

.flightCardWrapper {
  .FarDetailsCollapse {

    // .ant-collapse-header {
    //   // display: none;
    //   border-bottom: 1px solid #bd0c21;
    // }
    .row-Headings {
      padding: 11px 11px;
      font-size: 10px;
      font-weight: 600;
      color: #1c2b39;
      background-color: #f3f6f9;
    }

    .row-Data {
      padding: 15px 0px 15px 11px;

      justify-content: center;
      font-size: 12px;

      box-shadow: 0 0 2px 0 #d7dce2;

      .fareRules-btn {
        cursor: pointer;
      }

      .fareRules-btn:hover {
        color: #bd0c21;
      }

      .FareTypeName {
        margin: 0 !important;
        font-size: 14px;
        font-weight: 900;
      }

      .FareTypeName:before {
        content: "";
        position: absolute;
        left: -11px;
        top: 3px;
        width: 4px;
        height: 16px;
        background-color: #bd0c21;
      }

      .sub-FareTypeName {
        margin: 0 !important;
      }
    }

    .price {
      font-size: 18px;
      font-weight: 600;
    }

    .bottom-airline-text {
      p {
        font-size: 13px;
      }
    }

    @include mobile {
      display: none;
    }
  }

  .ant-collapse-content-box {
    padding: 0px 5px !important;
  }

  .FarDetailsCollapse-Mobile {
    display: none;

    @include mobile {
      display: block;
    }

    .row-Data {
      padding: 15px 17px 15px 17px;
      justify-content: center;
      font-size: 12px;
      color: #8e93ab;
      box-shadow: 0 0 2px 0 #d7dce2;

      .text {
        color: #1c2b39 !important;
      }
    }

    .ant-collapse-header {
      display: none;
    }

    .FareTypeName,
    .price {
      margin: 0 !important;
      font-size: 14px;
      font-weight: 900;
      color: #1c2b39 !important;
    }

    .FareTypeName:before {
      content: "";
      position: absolute;
      left: -17px;
      top: 3px;
      width: 4px;
      height: 16px;
      background-color: #bd0c21;
    }

    .choose-web-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    span.fare-type-icon {
      padding-right: 5px;
    }
  }
}

.flights-btn-view {
  background-color: rgba(0, 0, 0, 0.03);
  // background-image: linear-gradient(180deg, #fafcfd, #fff);
  border-radius: 0 0 8px 8px;
  box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
}

.flightari-dep-iconsres {
  position: absolute;
  top: -34%;
  width: 10%;

  @include mobile {
    top: -97%;
  }
}

.flightari-dep-iconsres1 {
  position: absolute;
  top: -45%;
  width: 10%;

  @include mobile {
    top: -115%;
  }
}

// .fareTypeContainer {
//   .row-Headings {
//     background: #35459c;
//     color: #fff;
//     margin-bottom: 5px;
//   }

//   .row-Data {
//     border-left: 3px solid #ff7d00;
//     margin: 4px 1px;
//     background: linear-gradient(45deg, #bd0c2114, transparent);
//     padding: 6px;
//     border-radius: 4px;

//     &:hover {
//       box-shadow: 1px 1px 8px grey;
//       padding: 6px;
//       border-radius: 4px;
//       transition: all 0.3s ease;
//     }


//     .TypeName {
//       color: #35459c;
//       margin: 0;
//       max-width: 200px;
//       overflow: hidden;
//       text-overflow: ellipsis;

//       white-space: nowrap;

//       .flight-dir {
//         background: #707070;
//         /* border: 1px solid black; */
//         border-radius: 4px;
//         color: #fff;
//         font-size: 12px;
//         padding: 1px 3px;
//         margin-right: 2px;
//         font-family: sans-serif;
//       }

//     }

//     .details-btn {
//       border: 1px solid #bd0c21;
//       // padding: 1px 6px;
//       border-radius: 15px;

//       &:hover {
//         background: #bd0c21;
//         color: #ffff;
//       }
//     }

//     .bag-inf {
//       display: flex;
//       align-items: center;
//       justify-content: space-around;
//       font-size: 12px;
//       cursor: pointer;

//       .cabinTy {
//         border: 1px solid #35459c;
//         border-radius: 4px;
//         padding: 0px 6px;
//         background: #fff;
//       }

//       .bagCkIn {
//         border: 1px solid #35459c;
//         border-radius: 5px;
//         padding: 0px 6px;
//         background: #fff;
//         margin-right: 4px;
//       }

//       .seatIf {
//         border: 1px solid #35459c;
//         border-radius: 5px;
//         padding: 0px 5px;
//         background: #fff;
//         // box-shadow: 0px 1px 6px grey;
//         margin-right: 4px;
//       }

//       .refstatus {
//         border: 1px solid #35459c;
//         border-radius: 8px;
//         padding: 0px 5px;
//         background: #fff;
//         // box-shadow: 0px 1px 6px grey;
//         margin-right: 4px;
//       }
//     }

//     .priceCh {
//       display: flex;
//       justify-content: center;
//       align-items: center;

//       .pri-ad {
//         font-weight: 600;
//         font-size: 16px;
//       }

//       .pri-ad1 {
//         font-weight: 400;
//         font-size: 13px;
//         padding-top: 6px;
//         color: rgb(151, 151, 151);
//       }
//     }

//     .price-dat {


//       // .pri-ad {
//       //   font-weight: 600;
//       //   font-size: 20px;
//       // }

//       // .pri-ad1 {
//       //   font-weight: 400;
//       //   font-size: 14px;
//       //   padding-top: 6px;
//       //   color: rgb(151, 151, 151);
//       // }

//       .choose-button {
//         &:hover {
//           border: 1px solid #bd0c21;
//           color: #bd0c21;
//         }
//       }
//     }
//   }

//   .row-DataNdc {
//     border-left: 3px solid #00b436;
//     margin: 4px 1px;
//     background: linear-gradient(45deg, #bd0c2114, transparent);
//     padding: 6px;
//     border-radius: 4px;

//     &:hover {
//       box-shadow: 1px 1px 8px grey;
//       padding: 6px;
//       border-radius: 4px;
//       transition: all 0.3s ease;
//     }


//     .TypeName {
//       color: #35459c;
//       margin: 0;
//       max-width: 200px;
//       overflow: hidden;
//       text-overflow: ellipsis;

//       white-space: nowrap;

//       .flight-dir {
//         background: #707070;
//         /* border: 1px solid black; */
//         border-radius: 4px;
//         color: #fff;
//         font-size: 12px;
//         padding: 1px 3px;
//         margin-right: 2px;
//         font-family: sans-serif;
//       }

//     }

//     .details-btn {
//       border: 1px solid #bd0c21;
//       // padding: 1px 6px;
//       border-radius: 15px;

//       &:hover {
//         background: #bd0c21;
//         color: #ffff;
//       }
//     }

//     .bag-inf {
//       display: flex;
//       align-items: center;
//       justify-content: space-around;
//       font-size: 12px;
//       cursor: pointer;

//       .cabinTy {
//         border: 1px solid #35459c;
//         border-radius: 4px;
//         padding: 0px 6px;
//         background: #fff;
//       }

//       .bagCkIn {
//         border: 1px solid #35459c;
//         border-radius: 5px;
//         padding: 0px 6px;
//         background: #fff;
//         margin-right: 4px;
//       }

//       .seatIf {
//         border: 1px solid #35459c;
//         border-radius: 5px;
//         padding: 0px 5px;
//         background: #fff;
//         // box-shadow: 0px 1px 6px grey;
//         margin-right: 4px;
//       }

//       .refstatus {
//         border: 1px solid #35459c;
//         border-radius: 8px;
//         padding: 0px 5px;
//         background: #fff;
//         // box-shadow: 0px 1px 6px grey;
//         margin-right: 4px;
//       }
//     }

//     .priceCh {
//       display: flex;
//       justify-content: center;
//       align-items: center;

//       .pri-ad {
//         font-weight: 600;
//         font-size: 16px;
//       }

//       .pri-ad1 {
//         font-weight: 400;
//         font-size: 13px;
//         padding-top: 6px;
//         color: rgb(151, 151, 151);
//       }
//     }

//     .price-dat {


//       // .pri-ad {
//       //   font-weight: 600;
//       //   font-size: 20px;
//       // }

//       // .pri-ad1 {
//       //   font-weight: 400;
//       //   font-size: 14px;
//       //   padding-top: 6px;
//       //   color: rgb(151, 151, 151);
//       // }

//       .choose-button {
//         &:hover {
//           border: 1px solid #bd0c21;
//           color: #bd0c21;
//         }
//       }
//     }
//   }


// }
.fareTypeContainer {
  .row-Headings {
    background: #35459c;
    color: #fff;
    margin-bottom: 5px;
    text-align: center; // Centering text for mobile
  }

  .row-Data,
  .row-DataNdc {
    border-left: 3px solid #ff7d00;
    margin: 4px 1px;
    background: linear-gradient(45deg, #bd0c2114, transparent);
    padding: 6px;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap; // Allow content to wrap on smaller screens

    &:hover {
      box-shadow: 1px 1px 8px grey;
      transition: all 0.3s ease;
    }

    .TypeName {
      color: #35459c;
      margin: 0;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .flight-dir {
                background: #707070;
                /* border: 1px solid black; */
                border-radius: 4px;
                color: #fff;
                font-size: 12px;
                padding: 1px 3px;
                margin-right: 2px;
                font-family: sans-serif;
              }
    }

    .details-btn {
      border: 1px solid #bd0c21;
      border-radius: 15px;

      &:hover {
        background: #bd0c21;
        color: #fff;
      }
    }

    .bag-inf {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 12px;
      cursor: pointer;

      .bagCkIn {
        border: 1px solid #35459c;
        border-radius: 5px;
        padding: 0px 6px;
        background: #fff;
        margin-right: 4px;
      }

      .seatIf {
        border: 1px solid #35459c;
        border-radius: 5px;
        padding: 0px 5px;
        background: #fff;
        // box-shadow: 0px 1px 6px grey;
        margin-right: 4px;
      }

      .refstatus {
        border: 1px solid #35459c;
        border-radius: 8px;
        padding: 0px 5px;
        background: #fff;
        // box-shadow: 0px 1px 6px grey;
        margin-right: 4px;
      }
    }

    .priceCh,
    .price-dat {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .choose-button {
      &:hover {
        border: 1px solid #bd0c21;
        color: #bd0c21;
      }
    }

    // Mobile & Tablet specific styling
    @media (max-width: 768px) {
      flex-direction: column; // Stack the content vertically on smaller screens

      .TypeName {
        text-align: center;
      }

      .bag-inf,
      .priceCh,
      .price-dat {
        justify-content: center;
        margin: 5px 0; // Add space between sections on mobile
      }

      .bag-inf {
        justify-content: space-evenly;
      }

      .details-btn {
        width: 40%; // Make buttons full-width for better usability on mobile
      }
    }

    // Adjust for smaller mobile screens (phones)
    @media (max-width: 576px) {
      .TypeName {
        font-size: 14px; // Reduce font size for smaller screens
      }

      .bag-inf {
        justify-content: space-evenly;
      }

      .details-btn {
        font-size: 12px; // Adjust button size
        padding: 5px; // Reduce padding
        width: 40%;
      }

      .bag-inf,
      .priceCh,
      .price-dat {
        font-size: 10px; // Adjust font size
      }
    }
  }

  // NDC specific styling, if different from regular fare cards
  .row-DataNdc {
    border-left: 3px solid #00b436;
  }
}


.fare-summary-container {
  margin: 20px;
}

.fare-summary-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  p {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .fare-summary-table,
  .baggage-summary {
    flex: 1;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;

    table {
      width: 100%;
      border-collapse: collapse;

      .toggle-row {
        cursor: pointer;

        &:hover {
          background-color: #f1f1f1;
        }
      }

      .total-amount {
        float: right;
        font-weight: bold;
      }

      .total-row {
        font-weight: bold;
        border-top: 1px solid #ddd;
        padding-top: 10px;
      }
    }

    .baggage-info {
      border-top: 1px solid #ddd;
      padding-top: 10px;
      margin-top: 10px;

      p {
        margin: 5px 0;
      }
    }
  }

  .fare-info {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 20px;

    p {
      display: flex;
      align-items: center;

      i {
        margin-right: 5px;
      }
    }
  }
}

.fare-summary-table {
  width: 100%;
  border-collapse: collapse;
}

.fare-summary-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.toggle-row {
  cursor: pointer;
  background-color: #f9f9f9;
}

.total-row {
  font-weight: bold;
  background-color: #f0f0f0;
}

.total-amount {
  float: right;
}

/* Styles for the BaggageTable component */
#cityPairSelect {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
}

// .fare-summary-container {
//   .tab-flight-Info {
//     table {
//       width: 100%;
//       border-collapse: collapse;
//       margin-top: 20px;
//     }

//     thead {
//       background-color: #f2f2f2;
//     }

//     th,
//     td {
//       padding: 10px;
//       text-align: left;
//       border: 1px solid #ddd;
//     }

//     th {
//       font-weight: bold;
//     }

//     tbody tr:nth-child(even) {
//       background-color: #f9f9f9;
//     }

//     tbody tr:hover {
//       background-color: #f1f1f1;
//     }


//   }
// }
.fare-summary-container {
  .tab-flight-Info {
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }

    thead {
      background-color: #f2f2f2;
    }

    th,
    td {
      padding: 10px;
      text-align: left;
      border: 1px solid #ddd;
    }

    th {
      font-weight: bold;
    }

    tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tbody tr:hover {
      background-color: #f1f1f1;
    }
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .fare-summary-container {
    .tab-flight-Info {

      table,
      th,
      td {
        display: block;
        width: 100%;
      }

      th,
      td {
        box-sizing: border-box;
        padding: 10px;
        text-align: left;
      }

      th::before,
      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-align: left;
      }

      th {
        display: none;
      }

      tbody tr {
        margin-bottom: 10px;
        border: 1px solid #ddd;
      }

      tbody tr:nth-child(even) {
        background-color: #fff;
      }

      tbody tr:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

.fareRule-container {
  // .Fare-modal {
  //   display: flex;

  //   .Fare-cont {
  //     width: 34%;

  //     .Fare-bagin {
  //       border: 1px solid #ddd;
  //       // padding: 10px;
  //       border-radius: 5px;
  //       background-color: #f9f9f9;
  //     }
  //   }

  //   .fare-summ-info {
  //     width: 64%;
  //     border: 1px solid #ddd;
  //     padding: 2px 4px;
  //     border-radius: 5px;
  //     background-color: #f9f9f9;
  //     max-height: 307px;
  //     overflow: auto;
  //     scroll-behavior: smooth;
  //     box-shadow: 2px 3px 10px #c2c1c1;

  //   }

  //   .fare-summ-inf1 {
  //     border: 1px solid #ddd;
  //     padding: 2px 4px;
  //     border-radius: 5px;
  //     background-color: #f9f9f9;
  //     max-height: 307px;
  //     overflow: auto;
  //     scroll-behavior: smooth;
  //     box-shadow: 2px 3px 10px #c2c1c1;
  //   }
  // }
  .Fare-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    background-color: #f8f9fa;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 10px;
    }

    .Fare-cont {
      flex: 1;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      margin-right: 20px;
      padding: 15px;

      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 15px;
      }

      .Fare-box {
        .fare-summary-container {
          .fare-summary-table {
            p {
              font-size: 18px;
              font-weight: bold;
              color: #333;

              @media (max-width: 768px) {
                font-size: 16px;
              }
            }

            .tab-flight-Info {
              width: 100%;
              border-collapse: collapse;

              tr {
                td {
                  padding: 8px 15px;
                  border-bottom: 1px solid #ddd;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  @media (max-width: 768px) {
                    font-size: 14px;
                    padding: 6px 10px;
                    display: block;
                  }

                  .total-amount {
                    font-weight: 600;
                    font-size: 16px;
                    color: #35459c;

                    @media (max-width: 768px) {
                      font-size: 14px;
                    }
                  }

                  &.total-row {
                    font-weight: bold;
                    color: #35459c;
                  }

                  &.toggle-row {
                    font-weight: normal;
                  }

                  span {
                    &.total-amount {
                      font-size: 16px;
                      font-weight: bold;

                      @media (max-width: 768px) {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .fare-summ-info {
      flex: 0.4;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      padding: 15px;

      p {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .Fare-rule-info {
        div {
          border-top: 1px solid #ddd;
          padding: 10px;
          font-size: 14px;
          color: #555;
        }
      }

      @media (max-width: 768px) {
        p {
          font-size: 14px;
        }

        .Fare-rule-info {
          div {
            font-size: 12px;
          }
        }
      }
    }
  }


  .fare-flig-inf {
    font-size: 12px;

    p {
      margin: 0;
    }
  }
}