@import "../../assets/variableStyles.scss";
.multi-rows{
  display: flex;
  justify-content: space-between;
}

.agent-flight-wrapper {
  // background-color: #2f81ed;
  // background-image: linear-gradient(to right, #bd0c21, #35459c);
  padding-bottom: 20px;
  position: absolute;
  top: 4%;
  left: 7%;
width: 80%;
  @include tab {
    padding-bottom: 20px;
    position: absolute;
    top: 4%;
    left: 0;
    width: 100%;


  }

  @include mobile {
    // background-color: #2f81ed;
    // background-image: linear-gradient(to right, #bd0c21, #35459c);
    background-image: linear-gradient(to right, #bd0c215f, #35459c62);
    position: initial;
    // top: 30%;
    // left: 15%;
    padding-top: 25%;
  }

  .ant-form {
    padding: 15px 0;
    padding-top: 1%;

    .ant-form-item-label {
      label {
        color: #fff;
        font-weight: 600;
      }
    }

    .ant-form-item {
      margin-bottom: 5px;

      input {
        color: #000000;
        font-weight: 600;
      }

      .ant-select-selector {
        height: 40px !important;
        font-weight: 600;
      }

      .ant-select-selection-item {
        line-height: 40px !important;
        font-weight: 600;
        color: #000000;
        font-size: 15px;
      }

      .ant-select-selection-placeholder {
        font-weight: 600;
        color: #000000;
      }
    }

    .ff-agent-search {
      background: #bd0c21 !important;
      text-align: center;
      display: block;
      color: #fff;
      font-size: 16px;
      margin-left: auto;
      border-radius: 5px;
    }
  }

  .coolaspe-airline-op {
    margin-bottom: 15px;

    .ant-collapse {
      background-color: transparent;
      border: 0px solid #d9d9d9;
    }

    .ant-collapse-item {
      border-bottom: 0px solid #d9d9d9;
    }

    .ant-collapse-content {
      background: transparent;
      border-top: 0px solid;

      .ant-collapse-content-box {
        padding: 0;
      }
    }

    .ant-collapse-header {
      color: #ffffff !important;
      display: flex;
      align-items: center;
      font-weight: 600;
      padding-left: 0;
    }

    label {
      color: #333;
      font-weight: normal;
    }

    .restrict-select {
      color: #333;
      font-weight: 600;

      span {
        margin-right: 10px;
        color: #fff;
      }
    }
  }
}

.cms-pages-width1 {
  max-width: 1100px !important;
  margin: auto;
  padding: 0 15px;
  width: 180%;

  @include tab {
    width: 100%;
  }

  @include mobile {
    width: auto;
  }
}

.ourteam-bg1 {
  background: #78787875;
  margin: 0 auto;
  border-radius: 3px;

  @include mobile {
    background: transparent !important;
  }
}