@import "../assets/variableStyles.scss";

$primary: #d5436a;
$secondry: #05004e;

.add-deposit-icon {
  font-size: 30px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.from-to-block {
  display: flex;
  .date-pickers {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
}
.cancel-btn {
  margin-left: 10px;
}
.upload-files {
  text-align: end;
  margin-bottom: 24px;
  @include mobile {
    text-align: start;
  }
}
.payment-details-block {
  margin-bottom: 15px;
  margin-left: 33%;
  @include mobile {
    margin-left: 0;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }
  ul {
    li {
      display: flex;
      justify-content: space-between;
      p {
        margin-bottom: 0;
      }
    }
  }
}
@include mobile {
  .certify-text {
    .ant-form-item-control {
      margin-left: auto;
    }
  }
}

#admin {
  h5,
  h6,
  .panel_wrapper .ant-collapse-header {
    color: $secondry !important;
  }
  .panel_wrapper .ant-collapse-header {
    font-size: 18px;
  }

  .panel_wrapper .ant-collapse-header .ant-collapse-arrow {
    font-size: 16px;
  }

  .logo {
    width: auto !important;
    overflow: hidden;
    padding: 8px;
  }

  .panel_wrapper {
    background-color: #fff;
  }
  .ant-collapse-header {
    font-weight: 600;
    .ant-collapse-header {
      font-weight: inherit;
    }
  }
  .ant-form-item-label label,
  .upload-logo p,
  .ant-upload .ant-upload-text {
    font-weight: 600;
  }

  header {
    background-color: white;
  }
  .adminFooter {
    text-align: center;
    margin: 0;
  }
  .anticon-upload,
  .ant-radio-button-wrapper-checked,
  .ant-picker-content tr th {
    font-weight: 600;
  }
  .ant-picker-content tr th {
    border: 1px solid #fff;
    padding: 5px 12px;
  }
}
.ant-table-thead {
  white-space: nowrap;
}
.ant-table-tbody {
  white-space: nowrap;
}
@include mobile {
  .ant-table {
    max-width: 100%;
    overflow: scroll;
  }
}

@include mobile {
  .manage-markup-section {
    margin-top: 18px;
    // padding: 0 !important;
  }
  .ant-card-body {
    padding: 15px;
  }
  .card-add-heading {
    flex-wrap: wrap;
    .rows-count {
      max-width: 100%;
      width: 100%;
    }
    h5 {
      margin-bottom: 0;
      font-size: 15px;
    }
    .add-icon {
      margin-bottom: 0;
      height: 30px;
      width: 30px;
    }
  }
  .ant-table-thead {
    tr {
      th {
        width: 100px !important;
      }
    }
  }
  .ant-modal-content {
    width: 90%;
    margin: auto;
  }
}

.site-layout-background {
  background: #fff;
}
.headerColor {
  .anticon {
    color: $secondry;
  }
}

.trigger {
  font-size: 18px;
  cursor: pointer;
}

/* admin Map scss/ */
.wrapModal .airpot_names_dropdown {
  max-width: 336px !important;
}

.ant-modal-title {
  color: #fff;
}

.seperation-line {
  border-top: solid 1px #ccc;
  padding-top: 20px;
}

.card-gap {
  margin-top: 20px;
  .ant-card-head {
    background: #bd0c21 !important;
    color: #fff !important;
  }
  .flight-depart-details {
    .flight-details {
      border-top: solid 1px #ccc;
      padding-top: 30px;
      margin-top: 20px;
      .flight-title {
        text-align: center;
      }
    }
  }
}
.ant-menu {
  .fa {
    margin-right: 10px !important;
  }
}
#admin {
  .logo {
    height: 64px !important;
    width: auto !important;
    overflow: hidden;
    padding: 0 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100% !important;
      height: auto;
      max-height: 50px;
    }
  }
  .ant-menu {
    .fa {
      margin-right: 10px !important;
    }
  }
  .ant-layout-sider-collapsed {
    @include mobile {
      // min-height: 100vh;
      flex: 0 0 60px !important;
      width: 60px !important;
      min-width: 60px !important;
      max-width: 60px !important;
    }
  }
  .ant-menu-inline-collapsed {
    @include mobile {
      width: 60px !important;
      text-align: center;
    }
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    height: 20px !important;
    line-height: 20px;
    @include mobile {
      margin: 0 !important;
      padding: 0 16px !important;
    }
  }
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 32px !important;
    line-height: 28px;
    @include mobile {
      padding: 0 16px !important;
    }
  }

  .ant-layout-header {
    height: 70px !important;
    line-height: 70px !important;
    padding: 0px 13px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
      padding: 0px !important;
    }
  }
  .footer {
    background-color: #bd0c21;
    padding: 24px !important;
    @include mobile {
      margin-top: 20px;
    }
  }
}
.bookoffline-submit {
  text-align: right;
  button {
    background: #bd0c21;
    color: #fff;
    font-size: 20px;
    padding: 20px 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.checkbox-flex {
  display: flex;
}

/* settings */

.profile_pic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-upload-list {
    margin-bottom: 33px;
  }
  img {
    width: 150px;
    height: 150px;
  }
}
.profile-details {
  .profile-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #ccc;
    margin: 10px 0px;
  }
}
.profile-update {
  .member-since {
    display: flex;
    justify-content: space-between;
    color: #bd0c21;
    font-weight: 600;
  }
  .update-btn {
    button {
      border: none;
      background: #bd0c21;
      color: #fff;
      width: 100%;
    }
  }
}

.gst-section {
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
}
.left-details {
  @include mobile {
    order: 1;
    margin-top: 25px;
  }
}
/* API logs */
.copy-btn {
  text-align: right;
  button {
    background: #bd0c21;
    color: #fff;
    border: none;
    font-size: 12px;
    height: 27px;
    &:hover {
      background-color: #fff;
      border: solid 1px #bd0c21;
    }
  }
}

.action-images {
  img {
    cursor: pointer;
  }
}
.admin-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.view-dn {
  position: relative;
  top: -51px;
  border: 1px solid;
  padding: 3px 5px;
  left: 49px;
}
.all-view-h5 {
  position: relative;
  top: -20px;
}

.view-dn-new {
  position: relative;
  top: -42px;
  border: 1px solid;
  padding: 3px 5px;
  left: 49px;
}
.all-view-h5-new {
  position: relative;
  top: -20px;
}

.inner-drop-down {
  padding-left: 50px !important;
}
/*09-09-2020--changes--*/
.admin-preference {
  width: 900px !important;
}

.ant-table-content {
  overflow: scroll;
}

.ant-table-pagination-right {
  -ms-flex-pack: end;
  justify-content: center;
}
.password-changes {
  font-weight: 700;
  text-align: center;
}
.fee-conven {
  margin-top: 20px;
}
.ant-menu-item {
  height: 26px !important;
  line-height: 26px !important;
  margin-bottom: 0px !important;
}
@media screen and (max-width: 768px) {
  #admin {
    margin-top: -60px;
  }
  #admin .trigger {
    right: 3px !important;
  }
}
#admin {
  .ant-table-tbody {
    line-height: 0.5715 !important;
    font-size: 13px !important;
  }
  .ant-table-thead {
    line-height: 0.5715 !important;
    font-size: 13px !important;
  }
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > th {
    padding: 5px 8px !important;
  }
}






.navigationmain-box{
  border: 1px solid #f6f7fb;
  margin-bottom: 1%;
  padding: 0.5%;
  background-color: white;
  visibility: visible;
  @include mobile{
    visibility: hidden;
    padding: 0%;
    margin: 0%;
  }
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 1%;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.navigation-color{
  margin-left: 1%;
  color: black;
  font-weight: bold;
}

.navigation-color:hover{
  color: orange;
}